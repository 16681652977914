import { types } from '../../../src/store/types/types';
import { portal } from '../../assets/plugins/axios/axios.js';
import Vue from 'vue';
import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';
import { messageFiltersEnum } from '../../enums/messageFiltersEnum';
import { messageTypes } from '../../enums/messageTypes';
import { messageOwnerTypes } from '../../enums/messageOwnerTypes';
import { portalRoles } from '../../enums/portalRoles';
import { httpStatus } from '../../enums/httpStatus';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import { parentTypes } from '../../enums/parentTypes';
import { sensitivityLevel } from '../../enums/sensitivityLevel';
import { orderDirections } from '../../enums/orderDirections';
import axios from 'axios';
import { GetMessagesForThread } from '../../services/api/GetMessagesForThread';
import { errorAttachmentStatus } from '../../enums/errorAttachmentStatus';
import { subscriptionStatus } from '../../enums/subscriptionStatus';
import { subscriptionTypes } from '../../enums/subscriptionTypes';
import { isProfileOTP } from '../../functions/isProfileOtp';
import { messageUtil } from '../../utils/messageUtil';
import { pageSizeOption } from '../../enums/pageSizeOption';
import { folderTypes } from '../../enums/folderTypes';
import { errorSubCodeTypes } from '../../enums/errorSubCodeTypes';

const encrypt = function (text, key) {
  return AES.encrypt(text, key).toString();
};

const decrypt = function (ciphertext, key) {
  try {
    const bytes = AES.decrypt(ciphertext, key);
    return bytes.toString(ENC);
  } catch (error) {
    return {};
  }
};

let scrollBottom;

const saveScrollPosition = el => {
  scrollBottom = el.scrollHeight - el.scrollTop;
};

const restoreScrollPosition = el => {
  Vue.nextTick(() => {
    el.scrollTop = el.scrollHeight - scrollBottom;
  });
};

const state = getDefaultState();

const getters = {
  [types.MESSAGES_GET_OTP_INBOXES]: (state, rootGetters) => {
    const activeChildren = rootGetters[types.GET_ACTIVE_CHILDREN];
    const activeGroupHome = rootGetters[types.GET_ACTIVE_GROUP_HOME];
    const optInboxResults = [];
    const activeGroupHomeIds = activeGroupHome ? [activeGroupHome.id] : [];
    const activeOtpInboxes = state.otpInboxes.filter(
      otpInbox =>
        activeGroupHomeIds.includes(otpInbox.groupHomeId) &&
        activeChildren.includes(otpInbox.regardingChild.institutionProfileId)
    );
    for (const otpInbox of activeOtpInboxes) {
      if (activeGroupHome != null) {
        const child = activeGroupHome.children.find(child => child.id == otpInbox.regardingChild.institutionProfileId);
        if (child != null) {
          otpInbox.name = activeGroupHome.name;
          otpInbox.childName = child.name;
          optInboxResults.push(otpInbox);
        }
      }
    }
    return optInboxResults;
  },
  [types.IS_LOADING_THREADS]: state => state.isLoadingThreads,
  [types.MESSAGES_GET_LATEST_MOVED_THREADS]: state => state.latestMovedThreads,
  [types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER]: state => state.chosenFolderAndMailOwner,
  [types.MESSAGES_GET_SELECTED_SUBSCRIPTION_ID]: state => state.shownSubscriptionId,
  [types.MESSAGES_GET_IN_SEARCH]: state => state.inSearch,
  [types.MESSAGES_GET_ACTIVE_SEARCH_QUERY]: state => state.messagesSearchQuery,
  [types.MESSAGES_GET_SUBSCRIPTIONS]: state => state.subscriptions,
  [types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS]: () => getSubscriptionsAndBundleThreads(),
  [types.MESSAGES_GET_CURRENT_DRAFT_MESSAGE]: state => state.currentDraftMessage,
  [types.MESSAGES_GET_SUBSCRIPTIONS_INDEX]: state => state.subscriptionsIndex,
  [types.MESSAGES_GET_SELECTED_SUBSCRIPTION]: state => {
    if (state.shownSubscription != null) {
      const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
      const subscriptionChosen = subscriptionsAndBundleThreads.find(item => item.id == state.shownSubscriptionId);
      state.shownSubscription.subscriptionId = subscriptionChosen?.subscriptionId || null;

      if (subscriptionChosen != null) {
        state.shownSubscription.subscriptionType = subscriptionChosen.subscriptionType;
        Vue.set(state.shownSubscription, 'marked', subscriptionChosen.marked);
      }

      if (subscriptionChosen != null && subscriptionChosen.leaveTime != undefined) {
        state.shownSubscription.leaveTime = subscriptionChosen.leaveTime;
      } else {
        state.shownSubscription.leaveTime = null;
      }
    }
    return state.shownSubscription;
  },
  [types.MESSAGES_GET_LAST_READ]: state => state.lastReadMessageId,
  [types.MESSAGES_GET_MORE_SUBSCRIPTIONS_EXIST]: state => state.moreSubscriptionsExist,
  [types.MESSAGES_GET]: state => state.messages,
  [types.MESSAGES_GET_OLD_DRAFT_THREAD_ID]: state => state.oldDraftThreadId,
  [types.MESSAGES_GET_ENCRYPTION_KEY]: (state, rootGetters) => rootGetters['global/GET_CURRENT_PROFILE'].encryptionKey,
  [types.MESSAGES_GET_DRAFT_THREADS]: (state, rootGetters) => {
    const idActive = rootGetters['global/GET_CURRENT_PROFILE'].id;
    const encryptionKey = rootGetters['global/GET_CURRENT_PROFILE'].encryptionKey;
    const institutions = rootGetters['global/GET_INSTITUTIONS'];
    const activeInboxIds = rootGetters[types.MESSAGES_GET_OTP_INBOXES].map(item => item.id);
    let mailBoxIds = [0];
    if (state.chosenFolderAndMailOwner.mailOwnerId) {
      mailBoxIds = [state.chosenFolderAndMailOwner.mailOwnerId];
    }
    if (activeInboxIds.length > 0) {
      mailBoxIds = activeInboxIds;
    }
    const currentFolder = state.chosenFolderAndMailOwner.folderId
      ? state.chosenFolderAndMailOwner.folderId
      : messageFiltersEnum.DEFAULT_FOLDER;
    const draftThreads = (localStorage.getItem('draftThread') && JSON.parse(localStorage.getItem('draftThread'))) || {};

    state.draftThreads = {};
    for (const mailBoxId of mailBoxIds) {
      for (const inst of institutions) {
        const id = inst.institutionProfileId;
        if (
          !(
            draftThreads[id] === undefined ||
            draftThreads[id][mailBoxId] === undefined ||
            draftThreads[id][mailBoxId][currentFolder] === undefined
          )
        ) {
          for (const key in draftThreads[id][mailBoxId][currentFolder]) {
            try {
              draftThreads[idActive][mailBoxId][currentFolder][key] = JSON.parse(
                decrypt(draftThreads[id][mailBoxId][currentFolder][key], encryptionKey)
              );
              state.draftThreads[key] = draftThreads[idActive][mailBoxId][currentFolder][key];
            } catch (e) {
              delete draftThreads[idActive]?.[mailBoxId]?.[currentFolder]?.[key];
            }
          }
        }
      }
    }
    return state.draftThreads;
  },
  [types.MESSAGES_GET_SELECTED_RECIPIENTS]: state => state.selectedRecipients,
  [types.MESSAGES_GET_UNREAD_THREADS]: state => state.unreadThreads,
  [types.MESSAGES_GET_SELECTED_DRAFT]: state => state.selectedDraft,
  [types.MESSAGES_GET_FOLDERS]: state => state.threadsFolders,
  [types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX]: state => {
    const currentCommonInbox = state.commonInboxes.find(c => c.id == state.chosenFolderAndMailOwner.mailOwnerId);
    return currentCommonInbox ? currentCommonInbox.folders : [];
  },
  [types.MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW]: state => state.showChooseSubscriptions,
  [types.MESSAGES_GET_CHOSEN_THREAD_IDS]: state => state.selectedThreadIds,
  [types.MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS]: state => state.selectedSubscriptionIds,
  [types.MESSAGES_GET_CHOSEN_BUNDLE_IDS]: state => state.selectedBundleIds,
  [types.MESSAGES_GET_AUTOREPLY]: state => state.autoReply,
  [types.GET_TYPEAHEAD_SUGGESTIONS]: state => state.typeaheadSuggestions,
  [types.MESSAGES_GET_MAIL_BOX_OWNERS]: state => state.selectableMailBoxOwners,
  [types.MESSAGES_GET_COMMON_INBOXES]: state => state.commonInboxes,
  [types.MESSAGES_GET_ALL_COMMON_INBOXES]: state => state.allCommonInboxes,
  [types.MESSAGES_GET_IN_SELECT_MODE]: state => state.messagesInSelectMode,
  [types.MESSAGES_GET_MORE_MESSAGES_EXITS]: state => state.moreMessagesExist,
  [types.MESSAGES_GET_PREFILLED_NEW_EMAIL_CONTACT]: state => state.preFilledNewEmailContact,
  [types.MESSAGES_GET_SINGLE_THREAD]: state => state.singleThread,
  [types.MESSAGES_GET_THREAD_BUNDLES]: state => state.threadBundles,
  [types.MESSAGES_SELECTED_BUNDLE_ID]: state => state.selectedBundleId,
  [types.MESSAGES_HAS_NEW_THREADS]: state => state.hasNewThreads,
  [types.MESSAGES_HAS_NEW_MESSAGES]: state => state.hasNewMessages,
  [types.MESSAGES_GET_MESSAGE_REVISION_LIST_ADMIN]: state => state.messageRevisionList,
};

const mutations = {
  [types.MUTATE_DRAFT_MESSAGES_FROM_LOCAL_STORAGE]: state => {
    if (localStorage.getItem('draftMessages') == null) {
      return;
    }
    state.draftMessages = JSON.parse(localStorage.getItem('draftMessages'));
  },
  [types.MUTATE_MESSAGES_RESET_STATE]: state => {
    Object.assign(state, getDefaultState());
  },
  [types.MUTATE_OTP_INBOXES]: (state, payload) => {
    state.otpInboxes = payload;
  },
  [types.MUTATE_CHOSEN_FILTER]: (state, filter) => {
    state.chosenFolderAndMailOwner.filter = filter;
  },
  [types.MUTATE_IS_LOADING_THREADS]: (state, payload) => {
    state.isLoadingThreads = payload;
  },
  [types.MUTATE_SELECTED_RECIPIENTS]: (state, payload) => {
    state.selectedRecipients = payload;
  },
  [types.MUTATE_SINGLE_THREAD]: (state, payload) => {
    state.singleThread = payload.thread;
    if (payload.thread.subscriptionType === subscriptionTypes.BUNDLE_ITEM) {
      return;
    }
    const threadIndex = state.subscriptions.findIndex(t => t.id == payload.thread.id);
    if (threadIndex > -1) {
      state.subscriptions.splice(threadIndex, 1, payload.thread);
    } else {
      state.subscriptions.push(payload.thread);
    }
  },
  [types.MUTATE_CHOSEN_FOLDER_AND_MAIL_OWNER]: (state, payload) => {
    state.chosenFolderAndMailOwner = payload;
  },
  [types.MUTATE_INCREMENT_UNREAD_THREADS]: state => {
    state.unreadThreads++;
  },
  [types.MUTATE_DECREMENT_UNREAD_THREADS]: state => {
    state.unreadThreads--;
  },
  [types.MUTATE_SUBSCRIPTIONS_IN_SEARCH]: (state, payload) => {
    if (!payload) {
      state.messagesSearchQuery = '';
    }
    state.inSearch = payload;
  },
  [types.MUTATE_SUBSCRIPTIONS_SEARCH_QUERY]: (state, payload) => {
    Vue.set(state, 'messagesSearchQuery', payload);
  },
  [types.MUTATE_ADD_TO_DRAFT_THREAD]: (state, payloadOrign) => {
    const payload = cloneDeep(payloadOrign);

    if (payload.attachments != null && payload.attachments.length > 0) {
      let i = payload.attachments.length;
      while (i--) {
        if (payload.attachments[i].isLoading != undefined && payload.attachments[i].link == undefined) {
          payload.attachments.splice(i, 1); // remove all attachments from device as they are not correctly stored in drafts
        }
      }
    }
    let mailBoxId = 0;

    if (payload.selectedCommonInbox) {
      mailBoxId = payload.selectedCommonInbox;
    }

    if (payload.selectedOtpInbox) {
      mailBoxId = payload.selectedOtpInbox;
    }

    const currentFolder =
      state.chosenFolderAndMailOwner.folderId && state.chosenFolderAndMailOwner.folderType === folderTypes.NORMAL
        ? state.chosenFolderAndMailOwner.folderId
        : messageFiltersEnum.DEFAULT_FOLDER;

    const draftThreads = (localStorage.getItem('draftThread') && JSON.parse(localStorage.getItem('draftThread'))) || {};
    const id = payload.userId;
    let draftKeyInt = 0;
    let key = 'DRAFT' + draftKeyInt;
    const encryptionKey = payload.encryptionKey;

    if (draftThreads[id] === undefined) {
      draftThreads[id] = {};
      draftThreads[id][mailBoxId] = {};
      draftThreads[id][mailBoxId][currentFolder] = {};
    } else {
      if (draftThreads[id][mailBoxId] === undefined) {
        draftThreads[id][mailBoxId] = {};
        draftThreads[id][mailBoxId][currentFolder] = {};
      } else if (draftThreads[id][mailBoxId][currentFolder] === undefined) {
        draftThreads[id][mailBoxId][currentFolder] = {};
      }
    }

    while (draftThreads[id][mailBoxId][currentFolder][key] !== undefined) {
      draftKeyInt++;
      key = 'DRAFT' + draftKeyInt;
    }

    if (draftThreads[id][mailBoxId][currentFolder][payload.id] !== undefined) {
      key = payload.id;
    } else {
      payload.id = key;
    }

    payload.text = payload.latestMessage.text;
    payload.latestMessage.draft = true;
    payload.subject = payload.thread.subject;

    if (draftThreads[id][mailBoxId][currentFolder][key]) {
      const oldPayload = JSON.parse(decrypt(draftThreads[id][mailBoxId][currentFolder][key], encryptionKey));
      const oldSendDateTime = oldPayload && oldPayload.latestMessage && oldPayload.latestMessage.sendDateTime;

      if (oldSendDateTime && payload && payload.latestMessage && payload.latestMessage.sendDateTime) {
        const sendDateTime = payload.latestMessage.sendDateTime;
        payload.latestMessage.sendDateTime = oldSendDateTime;

        // revert sendDateTime
        if (!isEqual(JSON.parse(JSON.stringify(payload)), oldPayload)) {
          payload.latestMessage.sendDateTime = sendDateTime;
        }
      }
    }

    draftThreads[id][mailBoxId][currentFolder][key] = encrypt(JSON.stringify(payload), encryptionKey);
    localStorage.setItem('draftThread', JSON.stringify(draftThreads));
    localStorage.setItem('tempDraftThread', key);
    state.draftThreads = draftThreads;
    state.oldDraftThreadId = null;
  },
  [types.MUTATE_INIT_SUBSCRIPTIONS_ADM]: (state, payload) => {
    Vue.set(state, 'subscriptions', payload);
  },
  [types.MUTATE_INIT_SUBSCRIPTIONS]: (state, payload) => {
    const threads = [];
    for (const thread of payload.threads) {
      const threadId = thread.id;
      thread.draft = state.draftMessages[threadId] !== undefined;
      if (thread.draft) {
        const draftText = decrypt(state.draftMessages[threadId], payload.encryptionKey);
        if (draftText) {
          thread.text = draftText;
        }
      }
      thread.sensitivityLevel = thread.sensitive ? sensitivityLevel.HIGH : sensitivityLevel.MEDIUM;
      threads.push(thread);
    }
    state.isLoadingThreads = false;
    Vue.set(state, 'subscriptions', threads);
    Vue.set(state, 'chosenFolderAndMailOwner.folderId', payload.folderId);
    Vue.set(state, 'chosenFolderAndMailOwner.filter', payload.filterType);
    Vue.set(state, 'chosenFolderAndMailOwner.sort', payload.sort);
    Vue.set(state, 'chosenFolderAndMailOwner.order', payload.order);
    Vue.set(state, 'moreSubscriptionsExist', payload.moreMessagesExist);
    Vue.set(state, 'currentSubscriptonPage', 1);
  },
  [types.MUTATE_APPEND_SUBSCRIPTIONS]: (state, payload) => {
    for (const subscription of payload.threads) {
      if (state.subscriptions.some(item => item.id === subscription.id)) {
        continue;
      }

      const subscriptionId = subscription.id;
      subscription.sensitivityLevel = subscription.sensitive ? sensitivityLevel.HIGH : sensitivityLevel.MEDIUM;
      subscription.draft = state.draftMessages[subscriptionId] !== undefined;
      if (subscription.draft) {
        const draftText = decrypt(state.draftMessages[subscriptionId], payload.encryptionKey);
        if (draftText) {
          subscription.text = draftText;
        }
      }
      state.subscriptions.push(subscription);
    }
    Vue.set(state, 'moreSubscriptionsExist', payload.moreMessagesExist);
    Vue.set(state, 'currentSubscriptonPage', parseInt(state.currentSubscriptonPage) + 1);
  },
  [types.MUTATE_ADD_SUBSCRIPTION]: (state, payload) => {
    const newSubscriptions = {};
    for (const key in state.subscriptions) {
      newSubscriptions[key] = state.subscriptions[key];
    }
    newSubscriptions[payload.id] = payload;
    state.subscriptions = newSubscriptions;
  },
  [types.MUTATE_RESET_SUBSCRIPTION_LIST]: state => {
    state.subscriptions = [];
  },
  [types.MUTATE_INIT_THREADS_FOLDERS]: (state, payload) => {
    state.threadsFolders = payload;
  },
  [types.MUTATE_COMMON_INBOX_FOLDERS]: (state, payload) => {
    const currentCommonInbox = state.commonInboxes.find(c => c.id == payload.commonInboxId);
    if (currentCommonInbox) {
      currentCommonInbox.folders = payload.folders;
    }
  },
  [types.MUTATE_DELETE_THREADS_FOLDER]: (state, payload) => {
    if (payload.isCommonInboxFolder) {
      const currentCommonInbox = state.commonInboxes.find(c => c.id == payload.commonInboxId);
      if (currentCommonInbox) {
        currentCommonInbox.folders = currentCommonInbox.folders.filter(f => f.id != payload.id);
      }
    } else {
      state.threadsFolders = state.threadsFolders.filter(f => f.id != payload.id);
    }
  },
  [types.MUTATE_CHANGE_FOLDER_NAME]: (state, payload) => {
    if (state.chosenFolderAndMailOwner.folderId && state.chosenFolderAndMailOwner.folderId === payload.folderId) {
      state.chosenFolderAndMailOwner.folderName = payload.folderName;
    }
    if (payload.isCommonInboxFolder) {
      const currentCommonInbox = state.commonInboxes.find(c => c.id == payload.commonInboxId);
      if (currentCommonInbox) {
        const folder = currentCommonInbox.folders.find(f => f.id == payload.folderId);
        folder.name = payload.folderName;
      }
    } else {
      const folder = state.threadsFolders.find(f => f.id == payload.folderId);
      folder.name = payload.folderName;
    }
  },
  [types.MUTATE_SELECT_MESSAGE]: (state, payload) => {
    for (const key in state.messages.messages) {
      if (!state.messages.messages.hasOwnProperty(key)) {
        continue;
      }

      const obj = state.messages.messages[key];
      for (const prop in obj) {
        // skip loop if the property is from prototype
        if (!obj.hasOwnProperty(prop)) {
          continue;
        }
        if (prop == 'messageId' && payload == obj[prop]) {
          state.selectedMessage = obj;
        }
      }
    }
  },
  [types.MUTATE_DELETED_MESSAGE]: (state, { messageId, deletedAt }) => {
    const deletedMessageIndex = state.messages.findIndex(message => message.id === messageId);
    if (deletedMessageIndex === -1) {
      return;
    }
    state.messages[deletedMessageIndex].messageType = messageTypes.MESSAGE_DELETED;
    state.messages[deletedMessageIndex].deletedAt = deletedAt;
  },
  [types.MUTATE_EDITED_MESSAGE]: (state, { messageId, sendDateTime, text, attachments }) => {
    const editedMessageIndex = state.messages.findIndex(message => message.id === messageId);
    if (editedMessageIndex === -1) {
      return;
    }
    state.messages[editedMessageIndex].messageType = messageTypes.MESSAGE_EDITED;
    state.messages[editedMessageIndex].sendDateTime = sendDateTime;
    state.messages[editedMessageIndex].text = text;
    state.messages[editedMessageIndex].attachments = attachments;
  },
  [types.MUTATE_APPEND_MESSAGES]: (state, payload) => {
    let cachedMessage;
    const encryptionKey = payload.encryptionKey;

    if (state.shownSubscriptionId === payload.subscriptionId) {
      cachedMessage = state.currentDraftMessage;
    } else if (state.draftMessages[payload.subscriptionId]) {
      cachedMessage = decrypt(state.draftMessages[payload.subscriptionId], encryptionKey);
    } else {
      cachedMessage = '';
    }

    state.oldDraftId = state.shownSubscriptionId;
    state.shownSubscriptionId = payload.subscriptionId;
    const subscriptionChosen = state.subscriptions.filter(item => item.id == payload.subscriptionId);
    if (subscriptionChosen.length > 0 && subscriptionChosen[0] != null) {
      state.lastReadMessageId = subscriptionChosen[0].lastReadMessageId;
    }

    const tempMessages = payload.data.messages.map(obj => {
      obj.selected = false;
      return obj;
    });

    for (const message of tempMessages) {
      state.messages.push(message);
    }

    saveScrollPosition(payload.scrollElement);

    restoreScrollPosition(payload.scrollElement);

    state.shownSubscription.moreMessagesExist = payload.data.moreMessagesExist;

    const messageKeysSorted = Object.keys(state.messages).sort(function (a, b) {
      return new Date(state.messages[a].sendDateTime) - new Date(state.messages[b].sendDateTime);
    });
    const lastReadId = messageKeysSorted[messageKeysSorted.length - 1];

    if (subscriptionChosen.length > 0) {
      subscriptionChosen[0].lastReadMessageId = state.messages[lastReadId].id;
    }

    state.currentMessagePage = parseInt(state.currentMessagePage) + 1;
    state.moreMessagesExist = payload.data.moreMessagesExist;

    // stored temporarily so that any destroyed textbox text can be saved
    state.oldDraftMessage = state.currentDraftMessage ? state.currentDraftMessage : null;
    if (state.oldDraftMessage === null) {
      state.oldDraftId = null;
    }
    state.currentDraftMessage = cachedMessage;
  },
  [types.MUTATE_SELECT_SUBSCRIPTION]: (state, payload) => {
    let cachedMessage;
    const encryptionKey = payload.encryptionKey;
    const dataRecipient = payload.data.recipients.find(recipient =>
      isEqual(recipient.mailBoxOwner, payload.data.mailBoxOwner)
    );
    if (state.shownSubscriptionId === payload.subscriptionId) {
      cachedMessage = state.currentDraftMessage;
    } else if (state.draftMessages[payload.subscriptionId]) {
      cachedMessage = decrypt(state.draftMessages[payload.subscriptionId], encryptionKey);
    } else {
      cachedMessage = '';
    }

    state.currentMessagePage = 1;
    state.moreMessagesExist = payload.data.moreMessagesExist;

    state.oldDraftId = state.shownSubscriptionId;
    state.shownSubscriptionId = payload.subscriptionId;
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionChosen = subscriptionsAndBundleThreads.filter(item => item.id == payload.subscriptionId);
    if (subscriptionChosen.length > 0) {
      if (subscriptionChosen[0].latestMessage != null) {
        state.lastReadMessageId = payload.data.lastReadMessageId;
        subscriptionChosen[0].lastReadMessageId = payload.data.lastReadMessageId;
        if (typeof subscriptionChosen[0].latestMessage == Object) {
          subscriptionChosen[0].latestMessage.id = payload.data.lastReadMessageId;
        }
        subscriptionChosen[0].read = true;
      }
      if (dataRecipient) {
        subscriptionChosen[0].leaveTime = dataRecipient.leaveTime;
      }
    }

    state.messages = payload.data.messages.map(obj => {
      obj.selected = false;
      return obj;
    });

    if (payload.data) {
      state.shownSubscription = prepareShownSubscription(payload.data, subscriptionChosen[0]);
    }

    // stored temporarily so that any destroyed textbox text can be saved
    state.oldDraftMessage = state.currentDraftMessage ? state.currentDraftMessage : null;
    if (state.oldDraftMessage === null) {
      state.oldDraftId = null;
    }
    state.currentDraftMessage = cachedMessage;
  },
  [types.MUTATE_RESET_SHOWN_SUBSCRIPTION]: state => {
    state.shownSubscription = null;
  },
  [types.MUTATE_SELECTALL_MESSAGES]: (state, isSelected) => {
    state.messages.forEach(message => {
      if (messageUtil.getIsSelectableMessageType(message.messageType)) {
        message.selected = isSelected;
      }
    });
  },
  [types.MUTATE_RESET_SELECT_SUBSCRIPTION]: state => {
    Vue.set(state, 'shownSubscriptionId', -1);
  },
  [types.MUTATE_RESET_SELECTED_DRAFT]: state => {
    if (state.selectedDraft && Object.keys(state.selectedDraft).length > 0) {
      state.oldDraftThreadId = state.selectedDraft.id;
    }
    Vue.set(state, 'selectedDraft', {});
  },
  [types.MUTATE_MESSAGES_MUTE]: (state, { isMuted, subscriptionId }) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscription = subscriptionsAndBundleThreads.find(item => item.subscriptionId == subscriptionId);
    const bundle = state.threadBundles.find(bundle => bundle.bundleId == subscriptionId);

    if (subscription) {
      Vue.set(subscription, 'muted', isMuted);
    }
    if (state.shownSubscription?.subscriptionId == subscriptionId) {
      Vue.set(state.shownSubscription, 'muted', isMuted);
    }
    if (bundle != null) {
      for (const thread of bundle.threads) {
        Vue.set(thread, 'muted', isMuted);
      }
    }
  },
  [types.MUTATE_SWITCH_MARK_SUBSCRIPTIONS]: (state, payload) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();

    for (const id of payload.threadIds) {
      const subscription = subscriptionsAndBundleThreads.find(item => item.id == id);
      if (subscription) {
        Vue.set(subscription, 'marked', payload.isMarked);
      }
    }

    for (const subscriptionId of payload.subscriptionIds) {
      const subscription = subscriptionsAndBundleThreads.find(item => item.subscriptionId == subscriptionId);
      if (subscription) {
        Vue.set(subscription, 'marked', payload.isMarked);
      }

      const bundle = state.threadBundles.find(bundle => bundle.bundleId == subscriptionId);
      if (bundle != null) {
        for (const thread of bundle.threads) {
          Vue.set(thread, 'marked', payload.isMarked);
        }
      }
    }
  },
  [types.MUTATE_SWITCH_SUBSCRIPTIONS_READ_STATUS]: (state, payload) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const isRead = payload.status === subscriptionStatus.READ;

    for (const subscriptionId of payload.subscriptionIds) {
      const subscription = subscriptionsAndBundleThreads.find(item => item.subscriptionId === subscriptionId);

      if (subscription) {
        Vue.set(subscription, 'read', isRead);
      }

      const bundle = state.threadBundles.find(bundle => bundle.bundleId === subscriptionId);
      if (bundle != null) {
        for (const thread of bundle.threads) {
          Vue.set(thread, 'read', isRead);
        }
      }
    }
  },
  [types.MUTATE_BUNDLE_SUBSCRIPTIONS_CHECKED_STATUS_BY_BUNDLE_ID]: (state, payload) => {
    const subscriptionId = payload.bundleId;
    const statusToUpdate = payload.checked;

    const bundle = state.threadBundles.find(bundle => bundle.bundleId === subscriptionId);
    if (bundle != null) {
      for (const thread of bundle.threads) {
        Vue.set(thread, 'checked', statusToUpdate);
      }

      const threadIds = bundle.threads.map(thread => thread.id);
      const subscriptionIds = bundle.threads.map(thread => thread.subscriptionId);
      if (statusToUpdate) {
        state.selectedThreadIds = [...new Set(state.selectedThreadIds.concat(threadIds))];
        state.selectedSubscriptionIds = [...new Set(state.selectedSubscriptionIds.concat(subscriptionIds))];
      } else {
        state.selectedThreadIds = difference(state.selectedThreadIds, threadIds);
        state.selectedSubscriptionIds = difference(state.selectedSubscriptionIds, subscriptionIds);
      }
    }
  },
  [types.MUTATE_SUBSCRIPTIONS_CHECKED_STATUS]: (state, payload) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionId = payload.subscriptionId;
    const statusToUpdate = payload.checked;

    const subscription = subscriptionsAndBundleThreads.find(item => item.subscriptionId === subscriptionId);
    if (subscription) {
      Vue.set(subscription, 'checked', statusToUpdate);
    }

    const bundle = state.threadBundles.find(bundle => bundle.bundleId === subscriptionId);
    if (bundle != null) {
      Vue.set(bundle, 'checked', statusToUpdate);
    }
  },
  [types.MUTATE_UNCHECK_ALL_SUBSCRIPTIONS]: state => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    subscriptionsAndBundleThreads.forEach(subscription => {
      Vue.set(subscription, 'checked', false);
    });

    state.threadBundles.forEach(bundle => {
      Vue.set(bundle, 'checked', false);
    });
  },
  [types.MUTATE_DELETE_SUBSCRIPTION_BY_ID]: (state, payload) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionId = payload.subscriptionId;

    const subscription = subscriptionsAndBundleThreads.find(item => item.subscriptionId === subscriptionId);
    if (subscription) {
      Vue.set(subscription, 'deleted', true);
    }

    const bundle = state.threadBundles.find(bundle => bundle.bundleId === subscriptionId);
    if (bundle != null) {
      Vue.set(bundle, 'deleted', true);
    }
  },
  [types.MUTATE_LEAVE_SUBSCRIPTION]: (state, { threadId, lastMessage, profile, otpInboxId }) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionChosen = subscriptionsAndBundleThreads.find(
      item => item.id == threadId && item.subscriptionType !== subscriptionTypes.BUNDLE
    );
    let filteredRecipients = [];
    if (otpInboxId != null) {
      filteredRecipients = subscriptionChosen.recipients.filter(recipient => recipient.mailBoxOwner.id !== otpInboxId);
    } else {
      filteredRecipients = subscriptionChosen.recipients.filter(
        recipient => recipient.mailBoxOwner.profileId !== profile.profileId
      );
    }
    state.shownSubscription.leaveTime = true;
    Vue.set(subscriptionChosen, 'leaveTime', true);
    Vue.set(subscriptionChosen, 'lastReadMessageId', true);
    Vue.set(subscriptionChosen, 'latestMessage', lastMessage);
    Vue.set(subscriptionChosen, 'recipients', filteredRecipients);
    /** temporary remove this line for hotfix: AUlA-3882 */
    // Vue.set(state, 'messages', {});
  },
  [types.MUTATE_CHOSEN_THREAD_IDS]: (state, payload) => {
    state.selectedThreadIds = payload;
  },
  [types.MUTATE_CHOSEN_SUBSCRIPTION_IDS]: (state, payload) => {
    state.selectedSubscriptionIds = payload;
  },
  [types.MUTATE_CHOSEN_BUNDLE_IDS]: (state, payload) => {
    state.selectedBundleIds = payload;
  },
  [types.MUTATE_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW]: (state, payload) => {
    state.showChooseSubscriptions = payload;
  },
  [types.MUTATE_CREATE_AUTOREPLY]: (state, payload) => {
    state.autoReply = payload;
  },
  [types.MUTATE_DELETE_AUTOREPLY]: state => {
    state.autoReply = null;
  },
  [types.MUTATE_SUBMIT_MESSAGE]: (state, { message, threadId }) => {
    if (state.shownSubscriptionId == threadId) {
      state.messages.unshift(message);
      state.shownSubscription.messages.unshift(message);
      state.oldDraftMessage = null;
      state.oldDraftId = null;
      state.currentDraftMessage = '';
    }
    Vue.delete(state.draftMessages, threadId);
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionChosen = subscriptionsAndBundleThreads.find(item => item.id == threadId);
    if (subscriptionChosen) {
      const selfMessagePrefix = Vue.filter('fromTextKey')('MESSAGE_SELF_LATEST_MESSAGE_PREFIX');
      subscriptionChosen.latestMessage = cloneDeep(message);
      subscriptionChosen.latestMessage.text.html = `${selfMessagePrefix}: ${subscriptionChosen.latestMessage.text.html}`;
      subscriptionChosen.text = subscriptionChosen.latestMessage.text;
      subscriptionChosen.draft = false;
      subscriptionChosen.lastReadMessageId = subscriptionChosen.latestMessage.id;
      if (state.shownSubscriptionId == threadId) {
        state.lastReadMessageId = subscriptionChosen.lastReadMessageId;
      }
    }
    if (subscriptionChosen.subscriptionType !== subscriptionTypes.BUNDLE_ITEM) {
      appendNewThreads([subscriptionChosen]);
    }
    localStorage.setItem('draftMessages', JSON.stringify(state.draftMessages));
  },
  [types.MUTATE_THREAD_LATEST_MESSAGE]: (state, { latestMessage, threadId, messageId }) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const chosenSubscriptionIndex = subscriptionsAndBundleThreads.findIndex(item => item.id == threadId);
    if (subscriptionsAndBundleThreads[chosenSubscriptionIndex]?.latestMessage.id === messageId) {
      subscriptionsAndBundleThreads[chosenSubscriptionIndex].latestMessage.text.html = latestMessage;
    }
  },
  [types.MUTATE_INIT_AUTOREPLY]: (state, payload) => {
    if (payload != null) {
      payload.replyText = payload.replyText.html;
      state.autoReply = payload;
    }
  },
  [types.MUTATE_DELETE_SUBSCRIPTIONS]: (state, payload) => {
    const self = this;
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const threadIds = payload.threadIds ?? [];
    const subscriptionIds = payload.subscriptionIds ?? [];

    threadIds.forEach(currentThreadId => {
      if (currentThreadId.toString().match(/DRAFT[0-9]+$/)) {
        // A way to call other mutations
        self.a.mutations[types.MUTATE_DELETE_DRAFT_THREAD](state, {
          id: payload.id,
          key: currentThreadId,
        });
      } else {
        for (const subscription of subscriptionsAndBundleThreads) {
          if (currentThreadId === subscription.id && subscription.subscriptionType !== subscriptionTypes.BUNDLE) {
            Vue.set(subscription, 'deleted', true);
          }
        }
      }
    });

    subscriptionIds.forEach(currentSubscriptionId => {
      for (const subscription of subscriptionsAndBundleThreads) {
        if (currentSubscriptionId === subscription.subscriptionId) {
          Vue.set(subscription, 'deleted', true);
        }
      }
    });

    const shownThread = subscriptionsAndBundleThreads.find(item => item.id === state.shownSubscriptionId);
    if (shownThread && (threadIds.includes(shownThread.id) || subscriptionIds.includes(shownThread.subscriptionId))) {
      Vue.set(state, 'shownSubscriptionId', {});
      Vue.set(state, 'messages', {});
    }
  },
  [types.MUTATE_HIDE_MOVED_THREADS]: (state, payload) => {
    state.subscriptions = state.subscriptions.filter(s => !payload.includes(s.id));
  },
  [types.MUTATE_DELETE_DRAFT_THREAD]: (state, payload) => {
    const allDraftThreads = JSON.parse(localStorage.getItem('draftThread'));
    let mailBoxId = 0;
    if (state.chosenFolderAndMailOwner.mailOwnerId) {
      mailBoxId = state.chosenFolderAndMailOwner.mailOwnerId;
    }
    if (payload.selectedInbox) {
      if (payload.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
        mailBoxId = payload.selectedInbox.value;
      } else if (payload.selectedInbox.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
        mailBoxId = 0;
      }
    }
    const currentFolder =
      state.chosenFolderAndMailOwner.folderId && state.chosenFolderAndMailOwner.folderType === folderTypes.NORMAL
        ? state.chosenFolderAndMailOwner.folderId
        : messageFiltersEnum.DEFAULT_FOLDER;
    if (allDraftThreads?.[payload.id]?.[mailBoxId]?.[currentFolder] != undefined) {
      delete allDraftThreads[payload.id][mailBoxId][currentFolder][payload.key];
      localStorage.setItem('draftThread', JSON.stringify(allDraftThreads));
    }
    Vue.delete(state.draftThreads, payload.key);
    if (state.selectedDraft.id != undefined && state.selectedDraft.id == payload.key) {
      state.selectedDraft.isRemoved = true;
    }
  },
  [types.MUTATE_LATEST_MOVED_THREADS]: (state, payload) => {
    state.latestMovedThreads.currentFolder = payload.currentFolder;
    state.latestMovedThreads.threadIds = payload.threadIds;
    state.latestMovedThreads.moveToFolderId = payload.moveToFolderId;
  },
  [types.MUTATE_SELECT_DRAFT_THREAD]: (state, payload) => {
    if (payload.subscriptionId) {
      if (Object.keys(state.selectedDraft).length > 0) {
        state.oldDraftThreadId = state.selectedDraft.id;
      }
      state.selectedDraft = payload.draftThreads[payload.subscriptionId]
        ? payload.draftThreads[payload.subscriptionId]
        : {};
      state.oldDraftMessage = state.currentDraftMessage ? state.currentDraftMessage : null;
      if (state.oldDraftMessage === null) {
        state.oldDraftId = null;
      } else {
        state.oldDraftId = state.shownSubscriptionId;
      }
      state.currentDraftMessage = '';
      Vue.set(state, 'shownSubscriptionId', -1);
    } else {
      state.selectedDraft = {};
    }
  },
  [types.MUTATE_UPDATE_CURRENT_DRAFT_MESSAGE]: (state, payload) => {
    state.currentDraftMessage = payload;
  },
  [types.MUTATE_SAVE_DRAFT_MESSAGE]: (state, payload) => {
    function removeDraft(state, id) {
      const subscriptionChosen = state.subscriptions.filter(item => item.id == id);
      if (subscriptionChosen.length > 0) {
        Vue.set(subscriptionChosen[0], 'text', null);
        Vue.set(subscriptionChosen[0], 'draft', false);
        Vue.delete(state.draftMessages, id);
        localStorage.setItem('draftMessages', JSON.stringify(state.draftMessages));
        state.oldDraftMessage = null;
        state.oldDraftId = null;
      }
    }
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionChosen = subscriptionsAndBundleThreads.filter(item => item.id == payload.id);
    let subscriptionId;
    if (subscriptionChosen.length > 0) {
      // case when switching profile
      Vue.set(subscriptionChosen[0], 'draft', false);

      if (state.oldDraftMessage == null && state.currentDraftMessage == '') {
        // case when switching to thread without draft and draft content erased Draft should be removed and stop further handling
        removeDraft(state, payload.id);
        return;
      }
      if (state.oldDraftMessage !== null && state.oldDraftMessage !== '' && payload.id === state.oldDraftId) {
        Vue.set(state.draftMessages, payload.id, encrypt(state.oldDraftMessage, payload.encryptionKey));
        if (subscriptionChosen.length > 0) {
          Vue.set(subscriptionChosen[0], 'text', state.oldDraftMessage);
          Vue.set(subscriptionChosen[0], 'draft', true);
        }
        state.oldDraftMessage = null;
      } else if (state.currentDraftMessage) {
        if (state.oldDraftMessage == null && payload.navLocal) {
          // case when switching to thread with draft and draft content erased. Draft should be removed and stop further handling
          Vue.set(state.draftMessages, payload.id, encrypt(state.currentDraftMessage, payload.encryptionKey));
          Vue.set(subscriptionChosen[0], 'text', state.currentDraftMessage);
          Vue.set(subscriptionChosen[0], 'draft', true);
        } else {
          subscriptionId = state.shownSubscriptionId === -1 ? payload.id : state.shownSubscriptionId;
          Vue.set(state.draftMessages, subscriptionId, encrypt(state.currentDraftMessage, payload.encryptionKey));
          if (subscriptionChosen.length > 0) {
            Vue.set(subscriptionChosen[0], 'text', state.currentDraftMessage);
            Vue.set(subscriptionChosen[0], 'draft', true);
          }
          state.oldDraftMessage = null;
        }
      } else {
        if (subscriptionChosen.length === 0) {
          // case when switching profile
          return;
        }
        Vue.delete(state.draftMessages, payload.id);
        if (subscriptionChosen.length > 0) {
          Vue.set(subscriptionChosen[0], 'text', subscriptionChosen[0].latestMessage.text);
        }
        state.currentDraftMessage = '';
      }
      localStorage.setItem('draftMessages', JSON.stringify(state.draftMessages));
      state.oldDraftId = null;
      state.oldDraftMessage = null;
    }
  },
  [types.MUTATE_SELECTABLE_MAIL_BOX_OWNERS]: (state, payload) => {
    state.selectableMailBoxOwners = payload;
  },
  [types.MUTATE_LOAD_TYPEAHEAD_SUGGESTIONS]: (state, payload) => {
    state.typeaheadSuggestions = payload;
  },
  [types.MUTATE_SEARCH_SUBSCRIPTIONS]: (state, payload) => {
    let subscriptions = [];
    const searchSize = payload.searchResults.size;
    state.chosenFolderAndMailOwner.filter =
      state.chosenFolderAndMailOwner.filter !== messageFiltersEnum.FILTER_DRAFT
        ? state.chosenFolderAndMailOwner.filter
        : messageFiltersEnum.FILTER_ALL;
    if (payload.offset > 0) {
      subscriptions = state.subscriptions;
    }
    state.moreSubscriptionsExist = payload.searchResults.results.length === searchSize;

    if (payload.searchResults.results.length > 0) {
      for (const subscription of payload.searchResults.results) {
        if (subscription.delete != true) {
          for (const recipient of subscription.recipients) {
            recipient.displayName = recipient.fullName;
          }
          subscription.id = subscription.thread.id;
          subscription.latestMessage = subscription.searchMessage;
          subscription.regardingInstitutions = [];
          subscription.subject = subscription.thread.subject;
          subscription.sensitive = subscription.sensitivityLevel !== sensitivityLevel.MEDIUM;
          subscription.draft = state.draftMessages[subscription.id] != null;
          if (subscription.mailBoxOwner) {
            subscription.mailBoxOwnerType = subscription.mailBoxOwner.mailBoxOwnerType;
            subscription.mailBoxOwnerId = subscription.mailBoxOwner.id;
          }
          if (subscription.draft) {
            const draftText = decrypt(state.draftMessages[subscription.id], payload.searchResults.encryptionKey);
            if (draftText) {
              subscription.text = draftText;
            }
          }
          subscriptions.push(subscription);
        }
      }
    } else {
      state.moreSubscriptionsExist = false;
    }
    state.subscriptions = subscriptions;
  },
  [types.MUTATE_SET_SUBSCRIPTIONS_ON_THREAD]: (state, payload) => {
    const subscriptionId = payload.subscriptionId;
    const subscriptions = payload.subscriptions;
    if (!state.subscriptions[subscriptionId]) {
      return;
    }
    for (const recipient of payload.recipients) {
      const subscriptionChosen = state.subscriptions.filter(item => item.id == subscriptionId);
      if (subscriptionChosen.length > 0) {
        subscriptionChosen[0].thread.otherRecipients.push({
          displayName: recipient.name,
          shortName: recipient.shortName,
        });
      }
    }
    state.subscriptions[subscriptionId].thread.subscriptions = subscriptions;
  },
  [types.MUTATE_TOGGLE_COMMON_INBOX_FOLDERS]: (state, payload) => {
    for (const commonInbox of state.commonInboxes) {
      for (const folder of commonInbox.folders) {
        const hasNotifications = payload.notifications.filter(n => n.folderId === folder.id).length > 0;
        commonInbox.showFolders = hasNotifications;
      }
    }
  },
  [types.MUTATE_SET_COMMON_INBOXES]: (state, payload) => {
    state.commonInboxes = payload;
  },
  [types.MUTATE_SET_ALL_COMMON_INBOXES]: (state, payload) => {
    state.allCommonInboxes = payload;
  },
  [types.MUTATE_ADJUST_SENSITIVITY]: (state, payload) => {
    const subscriptionsAndBundleThreads = getSubscriptionsAndBundleThreads();
    const subscriptionChosen = subscriptionsAndBundleThreads.filter(item => item.id == state.shownSubscriptionId);
    if (subscriptionChosen.length > 0) {
      Vue.set(subscriptionChosen[0], 'sensitivityLevel', payload.sensitivityLevel);
      Vue.set(subscriptionChosen[0], 'sensitive', payload.sensitivityLevel !== sensitivityLevel.MEDIUM);
    }
    state.shownSubscription.sensitivityLevel = payload.sensitivityLevel;
    state.shownSubscription.sensitive = payload.sensitivityLevel !== sensitivityLevel.MEDIUM;
  },
  [types.MUTATE_ADJUST_ACKNOWLEDGED_SENSITIVITY]: state => {
    const subscriptionChosen = state.subscriptions.filter(item => item.id == state.shownSubscriptionId);
    if (subscriptionChosen.length > 0) {
      Vue.set(subscriptionChosen[0], 'sensitivityAcknowledged', true);
    }
  },
  [types.MUTATE_MESSAGES_IN_SELECT_MODE]: (state, payload) => (state.messagesInSelectMode = payload),
  [types.MUTATE_PREFILLED_NEW_EMAIL_CONTACT]: (state, payload) => {
    state.preFilledNewEmailContact = payload;
  },
  [types.MUTATE_RESET_PREFILLED_NEW_EMAIL_CONTACT]: state => {
    state.preFilledNewEmailContact = null;
  },
  [types.MUTATE_ADD_THREADS_IN_BUNDLE_BY_ID]: (state, payload) => {
    const bundleThread = state.subscriptions.find(subscription => subscription.subscriptionId === payload.bundleId);
    if (bundleThread) {
      bundleThread.recipients = payload.recipients;
    }

    payload.threads.forEach(thread => {
      const threadId = thread.id;
      thread.draft = state.draftMessages[threadId] !== undefined;
      if (thread.draft) {
        const draftText = decrypt(state.draftMessages[threadId], payload.encryptionKey);
        if (draftText) {
          thread.text = draftText;
        }
      }
      thread.sensitivityLevel = thread.sensitive ? sensitivityLevel.HIGH : sensitivityLevel.MEDIUM;
    });

    state.threadBundles = state.threadBundles.filter(bundle => bundle.bundleId !== payload.bundleId);
    state.threadBundles.push(payload);
  },
  [types.MUTATE_RESET_THREAD_BUNDLES]: state => {
    state.threadBundles = [];
  },
  [types.MUTATE_SELECTED_BUNDLE_ID]: (state, bundleId) => {
    state.selectedBundleId = bundleId;
  },
  [types.MUTATE_HAS_NEW_THREADS]: (state, hasNewThreads) => {
    state.hasNewThreads = hasNewThreads;
  },
  [types.MUTATE_HAS_NEW_MESSAGES]: (state, hasNewMessages) => {
    state.hasNewMessages = hasNewMessages;
  },
  [types.MUTATE_MESSAGE_REVISION_LIST_ADMIN]: (state, { messageRevisions, lastEvaluatedKey }) => {
    state.messageRevisionList.messageRevisions.push(...messageRevisions);
    state.messageRevisionList.lastEvaluatedKey = lastEvaluatedKey;
  },
  [types.MUTATE_RESET_MESSAGE_REVISION_LIST_ADMIN]: state => {
    state.messageRevisionList.messageRevisions = [];
    state.messageRevisionList.lastEvaluatedKey = null;
  },
};

const actions = {
  [types.MESSAGES_EMPTY_CURRENT_DRAFT_MESSAGE]: ({ commit }) => {
    commit(types.MUTATE_UPDATE_CURRENT_DRAFT_MESSAGE, '');
  },
  [types.INCREMENT_UNREAD_THREADS]: ({ commit }, payload) => {
    commit(types.MUTATE_INCREMENT_UNREAD_THREADS, payload);
  },
  [types.DECREMENT_UNREAD_THREADS]: ({ commit }, payload) => {
    commit(types.MUTATE_DECREMENT_UNREAD_THREADS, payload);
  },
  [types.ACTION_GET_NEW_THREADS]: ({ commit, rootState, rootGetters, dispatch }, lastPollingTimestamp) => {
    const params = {
      activeChildren: getActiveChildrenIds(rootState),
      folderId: getCurrentFolderId(state.chosenFolderAndMailOwner),
      lastPollingTimestamp,
      page: 0,
    };

    const mailboxOwnerParams = getMailboxOwnerParams(rootState, rootGetters, state.chosenFolderAndMailOwner);
    params.mailBoxOwnerIds = mailboxOwnerParams.mailBoxOwnerIds;
    params.mailBoxOwnerType = mailboxOwnerParams.mailBoxOwnerType;

    if (
      state.chosenFolderAndMailOwner.filter !== messageFiltersEnum.FILTER_ALL &&
      state.chosenFolderAndMailOwner.filter !== messageFiltersEnum.FILTER_DRAFT
    ) {
      params.filterOn = state.chosenFolderAndMailOwner.filter;
    }

    return portal
      .get('?method=messaging.getNewThreads', { params })
      .then(response => {
        const hasNewThreads = response.data.data.threads?.length > 0;
        commit(types.MUTATE_HAS_NEW_THREADS, hasNewThreads);

        if (!hasNewThreads) {
          return;
        }

        const newThreads = response.data.data.threads;
        // REVIEW: there is a possibility of another error here when (thread.subscriptionId === state.selectedBundleId && thread.subscriptionType === subscriptionTypes.BUNDLE
        // and we later on call getMessagesForThread with shownSubscriptionId
        // but it seems that we should have shownSubscriptionId set in that case
        // so this condition seems redundant
        const hasNewMessages = newThreads.some(
          thread =>
            thread.id == state.shownSubscriptionId ||
            (thread.subscriptionId === state.selectedBundleId && thread.subscriptionType === subscriptionTypes.BUNDLE)
        );

        commit(types.MUTATE_HAS_NEW_MESSAGES, hasNewMessages);
        appendNewThreads(newThreads);
        dispatch(types.ACTION_REFRESH_BUNDLES, response.data.data.threads);

        if (hasNewMessages) {
          dispatch(types.SELECT_SUBSCRIPTION, {
            subscriptionId: state.shownSubscriptionId,
            mailOwnerId: getSelectedMailBoxId(state.chosenFolderAndMailOwner, messageOwnerTypes.COMMON_INBOX),
            otpInboxId: getSelectedMailBoxId(state.chosenFolderAndMailOwner, messageOwnerTypes.OTP_INBOX),
          });
        }
      })
      .catch(() => {});
  },
  [types.ACTION_REFRESH_BUNDLES]: ({ dispatch }, threads) => {
    for (const thread of threads) {
      if (
        thread.subscriptionType === subscriptionTypes.BUNDLE &&
        state.threadBundles.some(bundle => bundle.bundleId === thread.subscriptionId)
      ) {
        dispatch(types.LOAD_THREADS_IN_BUNDLE_BY_ID, { bundleId: thread.subscriptionId });
      }
    }
  },
  [types.LOAD_MORE_MESSAGES]: ({ commit, rootGetters }, payload) =>
    portal
      .get('?method=messaging.getMessagesForThread', {
        params: {
          threadId: state.shownSubscriptionId,
          page: state.currentMessagePage,
          commonInboxId:
            state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX
              ? state.chosenFolderAndMailOwner.mailOwnerId
              : null,
          otpInboxId:
            state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
              ? state.chosenFolderAndMailOwner.otpInboxId
              : null,
        },
      })
      .then(response => {
        commit(types.MUTATE_APPEND_MESSAGES, {
          subscriptionId: state.shownSubscriptionId,
          data: response.data.data,
          scrollElement: payload.scrollElement,
          encryptionKey: rootGetters['global/GET_CURRENT_PROFILE'].encryptionKey,
        });
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
        if (error.response.data.status.code == httpStatus.FORBIDDEN) {
          if (error.response.data.status.subCode == 8) {
            commit(types.MUTATE_SET_NOTIFICATION_STEP_UP, {
              showStepUpNotification: true,
              redirectedUrl: window.location.href,
              parent: parentTypes.MESSAGES,
            });
          }
        } else {
          throw error;
        }
      }),
  [types.SET_LAST_READ_MESSAGE]: ({ state }, { messages, subscriptionId, mailOwnerId, otpInboxId }) => {
    const latestMessage = messages.find(message =>
      [
        messageTypes.MESSAGE,
        messageTypes.MESSAGE_EDITED,
        messageTypes.MESSAGE_DELETED,
        messageTypes.AUTO_REPLY,
      ].includes(message.messageType)
    );

    if (latestMessage != null && subscriptionId != null) {
      portal.post('?method=messaging.setLastReadMessage', {
        threadId: subscriptionId,
        messageId: latestMessage.id,
        commonInboxId: mailOwnerId,
        otpInboxId,
      });

      const chosenSubscription = state.subscriptions.find(item => item.id === subscriptionId);
      if (chosenSubscription != null) {
        chosenSubscription.lastReadMessageId = latestMessage.id;
      }
    }
  },
  [types.SELECT_SUBSCRIPTION]: ({ commit, rootGetters, state, dispatch, rootState }, payload) => {
    if (payload.subscriptionId && payload.subscriptionId.match && payload.subscriptionId.match(/^DRAFT/)) {
      payload.draftThreads = rootGetters[types.MESSAGES_GET_DRAFT_THREADS];
      commit(types.MUTATE_SELECT_DRAFT_THREAD, payload);
      return;
    } else {
      commit(types.MUTATE_RESET_SELECTED_DRAFT);
    }

    let mailOwnerId = null;
    let otpInboxId = null;
    let cancelToken = null;
    if (payload.mailOwnerId) {
      mailOwnerId = payload.mailOwnerId;
    }
    if (payload.otpInboxId) {
      otpInboxId = payload.otpInboxId;
    }

    if (payload.cancelToken != null) {
      cancelToken = payload.cancelToken;
    }

    return GetMessagesForThread.getMessagesForThread({
      params: {
        threadId: payload.subscriptionId,
        page: 0,
        commonInboxId: mailOwnerId,
        otpInboxId: otpInboxId,
      },
      cancelToken,
    })
      .then(response => {
        commit(types.MUTATE_SELECT_SUBSCRIPTION, {
          subscriptionId: payload.subscriptionId,
          data: response.data.data,
          encryptionKey: rootGetters['global/GET_CURRENT_PROFILE'].encryptionKey,
          currentProfile: rootState.globalShared.currentProfile,
        });
        commit(types.MUTATE_SELECTED_RECIPIENTS, response.data.data.recipients);
        if (!payload.subscriptionLeft) {
          if (
            payload.fromSearch &&
            response.data.data.mailBoxOwner.mailBoxOwnerType == messageOwnerTypes.COMMON_INBOX &&
            state.chosenFolderAndMailOwner.mailOwnerId != response.data.data.mailBoxOwner.id
          ) {
            state.chosenFolderAndMailOwner.mailOwnerId = response.data.data.mailBoxOwner.id;
            state.chosenFolderAndMailOwner.mailOwnerType = response.data.data.mailBoxOwner.mailBoxOwnerType;
            state.chosenFolderAndMailOwner.folderId = payload.folderId != null ? payload.folderId : null;
            setTimeout(() => {
              dispatch(types.INIT_SUBSCRIPTIONS, {});
            }, 5000);
          }
          state.isLoadingThreads = false;
        }
        if (!state.chosenFolderAndMailOwner.folderName) {
          state.chosenFolderAndMailOwner.folderName = getFolderName(payload.folderId);
        }
        if (!state.chosenFolderAndMailOwner.commonInboxName) {
          const commonInbox =
            state.commonInboxes?.find(inbox => inbox.id == state.chosenFolderAndMailOwner.mailOwnerId) || null;
          state.chosenFolderAndMailOwner.commonInboxName = commonInbox?.name || '';
        }

        dispatch(types.SET_LAST_READ_MESSAGE, {
          messages: response.data.data.messages,
          subscriptionId: payload.subscriptionId,
          mailOwnerId,
          otpInboxId,
        });
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error.message);
        }

        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
        if (error.response?.data?.status?.code == httpStatus.FORBIDDEN) {
          if (error.response?.data?.status?.subCode == 8) {
            commit(types.MUTATE_SET_NOTIFICATION_STEP_UP, {
              showStepUpNotification: true,
              redirectedUrl: window.location.href,
              parent: parentTypes.MESSAGES,
            });
          }
        } else {
          throw error;
        }
      });
  },
  [types.SELECT_MAIL_OWNER]: ({ commit }, payload) => commit(types.MUTATE_SELECT_MAIL_OWNER, payload),
  [types.RESET_SELECT_SUBSCRIPTION]: ({ commit }, payload) => {
    commit(types.MUTATE_RESET_SELECT_SUBSCRIPTION, payload);
  },
  [types.START_NEW_THREAD]: ({ commit, dispatch, rootGetters }, payload) => {
    let mailOwnerId = null;
    if (payload.mailBoxOwnerType && payload.creator) {
      mailOwnerId = {
        id: payload.creator,
        mailBoxOwnerType: payload.mailBoxOwnerType,
      };
    } else {
      mailOwnerId = {
        id: payload.creator || rootGetters['global/GET_CURRENT_PROFILE'].id,
        mailBoxOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
      };
    }
    const body = {
      subject: payload.subject,
      recipients: payload.recipients,
      bccRecipients: payload.bccRecipients,
      sensitive: payload.sensitivityLevel !== sensitivityLevel.MEDIUM,
      creator: mailOwnerId,
      message: {
        text: payload.text,
        attachmentIds: payload.attachments,
      },
      forwardInfo: payload.forwardInfo,
    };
    if (payload.recipients.length > 150) {
      commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_MANY_RECIPIENTS');
    }
    return portal
      .post('?method=messaging.startNewThread', body)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_CREATE');
        if (localStorage.getItem('tempDraftThread') != '') {
          const id = rootGetters['global/GET_CURRENT_PROFILE'].id;
          commit(types.MUTATE_DELETE_DRAFT_THREAD, {
            id: id,
            key: localStorage.getItem('tempDraftThread'),
          });
        }
        if (state.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX) {
          state.chosenFolderAndMailOwner.folderName = state.chosenFolderAndMailOwner.commonInboxName;
          state.chosenFolderAndMailOwner.folderId = null;
        } else {
          state.chosenFolderAndMailOwner.folderName = null;
          state.chosenFolderAndMailOwner.folderId = messageFiltersEnum.DEFAULT_FOLDER;
        }
        state.chosenFolderAndMailOwner.folderType = folderTypes.NORMAL;
        dispatch(types.UPDATE_THREADS_VIEW, {
          folderId: null,
          filterType: state.chosenFolderAndMailOwner.filter,
          mailOwnerId:
            state.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX
              ? state.chosenFolderAndMailOwner.mailOwnerId
              : null,
          otpInboxId:
            state.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX
              ? state.chosenFolderAndMailOwner.otpInboxId
              : null,
          sort: state.chosenFolderAndMailOwner.sort,
          order: state.chosenFolderAndMailOwner.order,
        });
        return Promise.resolve(response);
      })
      .catch(error => {
        if (error.response.data.status.subCode == errorAttachmentStatus.CROSS_MUNICIPAL_DOCUMENT) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CANNOT_SHARE_DOCUMENT_TO_OTHER_MUNICIPAL');
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_START_NEW_THREAD');
        }
        return Promise.reject(error.response.data);
      });
  },
  [types.REPLY_MESSAGE]: ({ commit }, payload) =>
    portal
      .post('?method=messaging.reply', {
        threadId: payload.subscriptionId,
        message: {
          text: payload.text,
          attachmentIds: payload.attachmentIds,
        },
        commonInboxId: payload.commonInboxId || null,
        otpInboxId: payload.otpInboxId || null,
      })
      .then(response => {
        commit(types.MUTATE_SUBMIT_MESSAGE, {
          message: response.data.data[0],
          threadId: payload.subscriptionId,
        });
        return response;
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_START_NEW_THREAD');
        return Promise.reject(error.response.data);
      }),
  [types.EDIT_MESSAGE]: ({ commit }, { subscriptionId, messageId, text, attachmentIds }) =>
    portal
      .post('?method=messaging.editMessage', {
        threadId: subscriptionId,
        messageId,
        messageRequest: {
          text,
          attachmentIds,
        },
      })
      .then(response => {
        const { text, sendDateTime, attachments } = response.data.data;
        const latestMessage = `${Vue.filter('fromTextKey')('MESSAGE_SELF_LATEST_MESSAGE_PREFIX')}: ${text.html}`;
        commit(types.MUTATE_EDITED_MESSAGE, { messageId, text, sendDateTime, attachments });
        commit(types.MUTATE_THREAD_LATEST_MESSAGE, { latestMessage, messageId, threadId: subscriptionId });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_START_NEW_THREAD');
      }),
  [types.DELETE_SUBSCRIPTIONS]: ({ commit, rootGetters }, payload) => {
    const id = rootGetters['global/GET_CURRENT_PROFILE'].id;
    let threadIds = payload.threadIds || [];
    const subscriptionIds = payload.subscriptionIds || [];

    threadIds = threadIds.filter(item => {
      if (item.match && item.match(/^DRAFT/)) {
        commit(types.MUTATE_DELETE_DRAFT_THREAD, {
          id: id,
          key: item,
        });
        return false;
      }
      return true;
    });

    // all messages that have been deleted are drafts
    if (threadIds.length + subscriptionIds.length === 0) {
      return;
    }

    const params = {
      threadIds,
      subscriptionIds,
      otpInboxId: payload.otpInboxId,
    };
    if (state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
      params.commonInboxId = state.chosenFolderAndMailOwner.mailOwnerId;
    }
    if (state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX) {
      params.otpInboxId = params.otpInboxId ?? state.chosenFolderAndMailOwner.mailOwnerId;
    }
    return portal
      .post('?method=messaging.deleteThreads', params)
      .then(() => {
        commit(types.MUTATE_DELETE_SUBSCRIPTIONS, {
          threadIds,
          subscriptionIds,
          id,
        });
        if (payload.length > 1) {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_DELETE_MULTI');
        } else {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_DELETE');
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_THREADS');
      });
  },
  [types.MESSAGES_SAVE_DRAFT_THREAD]: ({ commit, rootGetters }, payload) => {
    const userId = rootGetters['global/GET_CURRENT_PROFILE'].id;
    const encryptionKey = rootGetters['global/GET_CURRENT_PROFILE'].encryptionKey;
    payload.userId = userId;
    payload.encryptionKey = encryptionKey;
    commit(types.MUTATE_ADD_TO_DRAFT_THREAD, payload);
  },
  [types.MESSAGES_SAVE_DRAFT_MESSAGE]: ({ commit, rootState }, payload) => {
    commit(types.MUTATE_SAVE_DRAFT_MESSAGE, {
      id: payload.id,
      navLocal: payload.navLocal,
      currentDraftMessage: payload.currentDraftMessage,
      encryptionKey: rootState.globalShared.currentProfile.encryptionKey,
    });
  },
  [types.SWITCH_MARK_SUBSCRIPTIONS]: ({ commit }, payload) => {
    let mailOwnerId;
    let mailOwnerType;
    const threadIds = payload.threadIds || [];
    const subscriptionIds = payload.subscriptionIds || [];
    if (state.chosenFolderAndMailOwner.mailOwnerId != null) {
      mailOwnerId = state.chosenFolderAndMailOwner.mailOwnerId;
      mailOwnerType = state.chosenFolderAndMailOwner.mailOwnerType;
    }
    if (Array.isArray(payload.threadIds)) {
      for (const threadId of payload.threadIds) {
        if (!Number.isInteger(parseInt(threadId))) {
          return;
        }
      }
    }
    return portal
      .post('?method=messaging.setThreadsMarked', {
        threadIds: threadIds,
        subscriptionIds: subscriptionIds,
        marked: payload.isMarked,
        commonInboxId: mailOwnerType == messageOwnerTypes.COMMON_INBOX ? mailOwnerId : null,
        otpInboxId: mailOwnerType == messageOwnerTypes.OTP_INBOX ? mailOwnerId : null,
      })
      .then(() => {
        commit(types.MUTATE_SWITCH_MARK_SUBSCRIPTIONS, {
          threadIds: threadIds,
          subscriptionIds: subscriptionIds,
          isMarked: payload.isMarked,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_THREADS_MARKED');
      });
  },
  [types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS]: ({ commit }, payload) =>
    portal
      .post('?method=messaging.updateSubscriptionStatus', payload)
      .then(() => {
        commit(types.MUTATE_SWITCH_SUBSCRIPTIONS_READ_STATUS, payload);
      })
      .catch(() => {
        const errorTextKey =
          payload.subscriptionIds && payload.subscriptionIds.length > 1
            ? 'API_ERROR_UPDATE_SUBSCRIPTIONS'
            : 'API_ERROR_UPDATE_SUBSCRIPTION';
        commit(types.MUTATE_ERROR_TEXT, errorTextKey);
      }),
  [types.MESSAGES_LEAVE]: ({ commit, dispatch }, { threadId, otpInboxId, profile }) => {
    portal
      .post('?method=messaging.leaveThread', { threadId, otpInboxId })
      .then(response => {
        commit(types.MUTATE_LEAVE_SUBSCRIPTION, {
          threadId,
          lastMessage: response.data.data,
          profile,
          otpInboxId,
        });
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_LEAVE_THREAD');
      })
      .then(() => {
        dispatch(types.SELECT_SUBSCRIPTION, {
          subscriptionId: threadId,
          subscriptionLeft: true,
          otpInboxId,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LEAVE_THREAD');
      });
  },
  [types.MESSAGES_LEAVE_THREADS]: ({ commit }, { subscriptionIds }) =>
    portal.post('?method=messaging.leaveThreads', { subscriptionIds }).catch(() => {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LEAVE_THREADS');
    }),
  [types.INIT_SUBSCRIPTIONS_ADM]: ({ commit }, payload) =>
    portal
      .get('?method=messagingAdmin.getThreads', {
        params: {
          institutionProfileIds: [payload.mailOwnerId],
        },
      })
      .then(response => {
        commit(types.MUTATE_INIT_SUBSCRIPTIONS_ADM, response.data.data);
        return true;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      }),
  [types.INIT_SUBSCRIPTIONS]: ({ state, commit, rootState, rootGetters }) => {
    const params = {
      sortOn: messageFiltersEnum.SORT_DATE,
      orderDirection: orderDirections.DESCENDING,
      page: 0,
    };

    const mailboxOwnerParams = getMailboxOwnerParams(rootState, rootGetters, state.chosenFolderAndMailOwner);
    params.mailBoxOwnerIds = mailboxOwnerParams.mailBoxOwnerIds;
    params.mailBoxOwnerType = mailboxOwnerParams.mailBoxOwnerType;

    if (
      rootState.globalShared.currentProfile.role === portalRoles.GUARDIAN &&
      rootState.global.activeChildren.length !== rootState.global.children.length
    ) {
      params.activeChildren = rootState.global.activeChildren;
    }

    commit(types.MUTATE_RESET_SUBSCRIPTION_LIST);
    return portal
      .get('?method=messaging.getThreads', { params })
      .then(response => {
        response.data.data.folderId = messageFiltersEnum.DEFAULT_FOLDER;
        response.data.data.filterType = messageFiltersEnum.FILTER_ALL;
        response.data.data.sort = messageFiltersEnum.SORT_DATE;
        response.data.data.order = orderDirections.DESCENDING;
        response.data.data.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
        commit(types.MUTATE_SUBSCRIPTIONS_IN_SEARCH, false);
        commit(types.MUTATE_INIT_SUBSCRIPTIONS, response.data.data);
        return true;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      });
  },
  [types.LOAD_MORE_SUBSCRIPTIONS]: ({ state, commit, rootState, rootGetters }) => {
    const params = {
      page: state.currentSubscriptonPage,
      sortOn: state.chosenFolderAndMailOwner.sort,
      orderDirection: state.chosenFolderAndMailOwner.order,
    };
    if (
      rootState.globalShared.currentProfile.role === portalRoles.GUARDIAN &&
      rootState.global.activeChildren.length !== rootState.global.children.length
    ) {
      params.activeChildren = rootState.global.activeChildren;
    }
    if (state.chosenFolderAndMailOwner && state.chosenFolderAndMailOwner.folderId != 'inbox') {
      params.folderId = state.chosenFolderAndMailOwner.folderId;
    }
    if (state.chosenFolderAndMailOwner.filter != 'all' && state.chosenFolderAndMailOwner.filter != 'draft') {
      params.filterOn = state.chosenFolderAndMailOwner.filter;
    }

    const mailboxOwnerParams = getMailboxOwnerParams(rootState, rootGetters, state.chosenFolderAndMailOwner);
    params.mailBoxOwnerIds = mailboxOwnerParams.mailBoxOwnerIds;
    params.mailBoxOwnerType = mailboxOwnerParams.mailBoxOwnerType;

    return portal
      .get('?method=messaging.getThreads', { params })
      .then(response => {
        if (rootState.globalShared && rootState.globalShared.currentProfile) {
          response.data.data.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
        }
        return commit(types.MUTATE_APPEND_SUBSCRIPTIONS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      });
  },
  [types.ADD_SUBSCRIPTION]: ({ commit }, payload) => {
    commit(types.MUTATE_ADD_SUBSCRIPTION, payload);
  },
  [types.INIT_AUTOREPLY]: ({ commit }) => {
    portal
      .get('?method=messaging.getAutoReply', {})
      .then(response => {
        commit(types.MUTATE_INIT_AUTOREPLY, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_AUTO_REPLY');
      });
  },
  [types.UPDATE_THREADS_VIEW]: ({ state, commit, rootState, rootGetters }, payload) => {
    commit(types.MUTATE_IS_LOADING_THREADS, true);
    commit(types.MUTATE_RESET_SUBSCRIPTION_LIST);
    if (payload.filterType === messageFiltersEnum.FILTER_DRAFT) {
      payload.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
      commit(types.MUTATE_INIT_SUBSCRIPTIONS, payload);
      return;
    }
    const params = {
      sortOn: payload.sort,
      orderDirection: payload.order,
      page: 0,
    };
    if (
      rootState.globalShared.currentProfile.role === portalRoles.GUARDIAN &&
      rootState.global.activeChildren.length !== rootState.global.children.length
    ) {
      params.activeChildren = rootState.global.activeChildren;
    }

    const mailboxOwnerParams = getMailboxOwnerParams(rootState, rootGetters, state.chosenFolderAndMailOwner);
    params.mailBoxOwnerIds = mailboxOwnerParams.mailBoxOwnerIds;
    params.mailBoxOwnerType = mailboxOwnerParams.mailBoxOwnerType;

    if (payload.folderId != 'inbox') {
      params.folderId = payload.folderId;
    }
    if (payload.filterType != 'all' && payload.filterType != 'draft') {
      params.filterOn = payload.filterType;
    }
    return portal
      .get('?method=messaging.getThreads', { params })
      .then(response => {
        response.data.data.folderId = payload.folderId;
        response.data.data.filterType = payload.filterType;
        response.data.data.sort = payload.sort;
        response.data.data.order = payload.order;
        response.data.data.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
        commit(types.MUTATE_SUBSCRIPTIONS_IN_SEARCH, false);
        commit(types.MUTATE_INIT_SUBSCRIPTIONS, response.data.data);
        commit(types.MUTATE_CHOSEN_THREAD_IDS, []);
        commit(types.MUTATE_CHOSEN_SUBSCRIPTION_IDS, []);
        commit(types.MUTATE_IS_LOADING_THREADS, false);
        return true;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      });
  },
  [types.UPDATE_THREADS_VIEW_BY_SUBSCRIPTION]: ({ commit, rootState }, payload) => {
    const params = {
      threadIds: payload.subscriptionIds,
      sortOn: payload.sort,
      orderDirection: payload.order,
      filterOn: payload.filterType,
      page: 0,
    };
    if (
      rootState.globalShared.currentProfile.role === portalRoles.GUARDIAN &&
      rootState.global.activeChildren.length !== rootState.global.children.length
    ) {
      params.activeChildren = rootState.global.activeChildren;
    }
    if (payload.folderId != 'inbox') {
      params.folderId = payload.folderId;
    }
    if (payload.filterType != 'all' && payload.filterType != 'draft') {
      params.filterOn = payload.filterType;
    }
    portal
      .get('?method=messaging.getThreads', { params })
      .then(response => {
        response.data.data.folderId = payload.folderId;
        response.data.data.filterType = payload.filterType;
        response.data.data.sort = payload.sort;
        response.data.data.order = payload.order;
        response.data.data.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
        commit(types.MUTATE_INIT_SUBSCRIPTIONS, response.data.data);
        return true;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      });
  },
  [types.INIT_THREADS_FOLDERS]: ({ commit }) => {
    portal
      .get('?method=messaging.getFolders', {
        params: {
          includeDeletedFolders: false,
        },
      })
      .then(response => {
        commit(types.MUTATE_INIT_THREADS_FOLDERS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_FOLDERS');
      });
  },
  [types.LOAD_COMMON_INBOX_FOLDERS]: ({ commit }, payload) => {
    portal
      .get('?method=messaging.getFolders', {
        params: {
          commonInboxId: payload.commonInboxId,
          includeDeletedFolders: false,
        },
      })
      .then(response => {
        commit(types.MUTATE_COMMON_INBOX_FOLDERS, {
          folders: response.data.data,
          commonInboxId: payload.commonInboxId,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_FOLDERS');
      });
  },
  [types.DELETE_THREADS_FOLDER]: ({ commit }, payload) => {
    portal
      .post('?method=messaging.deleteFolder', {
        folderId: payload.id,
      })
      .then(() => {
        commit(types.MUTATE_DELETE_THREADS_FOLDER, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_FOLDER_DELETE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_FOLDER');
      });
  },
  [types.CHANGE_FOLDER_NAME]: ({ commit }, payload) =>
    portal
      .post('?method=messaging.updateFolder', payload)
      .then(() => {
        commit(types.MUTATE_CHANGE_FOLDER_NAME, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_FOLDER_RENAMED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CHANGE_FOLDER_NAME');
      }),
  [types.ADD_THREADS_FOLDER]: ({ dispatch, commit }, payload) =>
    portal
      .post('?method=messaging.createFolder', {
        folderName: payload,
      })
      .then(() => {
        dispatch(types.INIT_THREADS_FOLDERS);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_FOLDER_CREATE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_FOLDER');
      }),
  [types.ADD_THREADS_FOLDER_FOR_COMMON_INBOX]: ({ dispatch, commit }, payload) =>
    portal
      .post('?method=messaging.createFolder', {
        commonInboxId: payload.commonInboxId,
        folderName: payload.folderName,
      })
      .then(() => {
        dispatch(types.LOAD_COMMON_INBOX_FOLDERS, {
          commonInboxId: payload.commonInboxId,
        });
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_FOLDER_CREATE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_FOLDER');
      }),
  [types.ACTION_UPDATE_SELECTED_THREAD_IDS]: ({ commit }, payload) => {
    commit(types.MUTATE_CHOSEN_THREAD_IDS, payload);
  },
  [types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS]: ({ commit }, payload) => {
    commit(types.MUTATE_CHOSEN_SUBSCRIPTION_IDS, payload);
  },
  [types.ACTION_UPDATE_SELECTED_BUNDLE_IDS]: ({ commit }, payload) => {
    commit(types.MUTATE_CHOSEN_BUNDLE_IDS, payload);
  },
  [types.SUBSCRIPTIONS_ONPRESS_MOBILEVIEW]: ({ commit }, payload) => {
    commit(types.MUTATE_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW, payload);
  },
  [types.CREATE_AUTOREPLY]: ({ commit }, payload) =>
    portal
      .post('?method=messaging.setAutoReply', {
        replyText: payload.replyText,
        startDateTime: payload.startDateTime,
        endDateTime: payload.endDateTime,
      })
      .then(() => {
        commit(types.MUTATE_CREATE_AUTOREPLY, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_AUTOREPLY_CREATED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_AUTO_REPLY');
      }),
  [types.DELETE_AUTOREPLY]: ({ state, commit }, payload) => {
    if (state.autoReply.id != null) {
      portal
        .post('?method=messaging.deleteAutoReply', {
          autoReplyId: state.autoReply.id,
        })
        .then(() => {
          commit(types.MUTATE_DELETE_AUTOREPLY, payload);
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_AUTOREPLY_DELETED');
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_AUTO_REPLY');
        });
    }
  },
  [types.MOVE_THREADS_TO_FOLDER]: ({ state, commit }, payload) => {
    let folder = null;
    const threadIds = payload.threadIds ?? [];
    const subscriptionIds = payload.subscriptionIds ?? [];
    if (payload.folderId !== 'inbox') {
      folder = payload.folderId;
    }

    let mailOwnerId = null;
    if (state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX) {
      mailOwnerId = state.chosenFolderAndMailOwner.mailOwnerId;
    }

    const params = {
      folderId: folder,
      threadIds: threadIds,
      subscriptionIds: subscriptionIds,
      commonInboxId: mailOwnerId,
    };

    return portal
      .post('?method=messaging.moveThreadsToFolder', params)
      .then(() => {
        if (threadIds.length + subscriptionIds.length > 1) {
          commit(types.MUTATE_HIDE_MOVED_THREADS, threadIds);
          commit(types.MUTATE_LATEST_MOVED_THREADS, {
            currentFolder: state.chosenFolderAndMailOwner.folderId,
            threadIds: threadIds,
            moveToFolderId: payload.folderId,
          });
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_MOVE_THREADS_TO_FOLDER');
      });
  },
  [types.LOAD_TYPEAHEAD_SUGGESTIONS]: ({ commit }, payload) => {
    portal
      .get('?method=search.findMessage', {
        params: { text: payload },
      })
      .then(response => {
        commit(types.MUTATE_LOAD_TYPEAHEAD_SUGGESTIONS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_MESSAGE');
      });
  },
  [types.SEARCH_SUBSCRIPTIONS]: ({ state, commit, rootState }, payload) => {
    const profile = payload.profile;
    let commonInboxId = null;

    if (!isProfileOTP(profile) && state.chosenFolderAndMailOwner?.mailOwnerId) {
      commonInboxId = state.chosenFolderAndMailOwner.mailOwnerId;
    }

    const currenfolderId = getCurrentFolderId(state.chosenFolderAndMailOwner);
    return portal
      .post('?method=search.findMessage', {
        text: payload.text,
        typeahead: false,
        exactTerm: true,
        activeChildrenInstitutionProfileIds: payload.activeChildren,
        institutionCodes: payload.activeInstitutions,
        limit: pageSizeOption.DEFAULT_PAGE_SIZE,
        offset: payload.offset,
        commonInboxID: commonInboxId,
        folderId: currenfolderId,
        filterBy:
          state.chosenFolderAndMailOwner.filter !== messageFiltersEnum.FILTER_DRAFT
            ? state.chosenFolderAndMailOwner.filter
            : messageFiltersEnum.FILTER_ALL,
        sortBy: state.chosenFolderAndMailOwner.sort,
        sortDirection: state.chosenFolderAndMailOwner.order,
        threadSubject: payload.threadSubject,
        messageContent: payload.messageContent,
        fromDate: payload.fromDate,
        toDate: payload.toDate,
        threadCreators: payload.threadCreators,
        participants: payload.participants,
        hasAttachments: payload.hasAttachments,
      })
      .then(response => {
        if (rootState.globalShared && rootState.globalShared.currentProfile) {
          response.data.data.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
        }
        commit(types.MUTATE_SUBSCRIPTIONS_IN_SEARCH, true);
        commit(types.MUTATE_SEARCH_SUBSCRIPTIONS, {
          searchResults: response.data.data,
          offset: payload.offset,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_MESSAGE');
      });
  },
  [types.SET_SELECTABLE_MAIL_BOX_OWNERS]: ({ commit }, payload) => {
    commit(types.MUTATE_SELECTABLE_MAIL_BOX_OWNERS, payload);
  },
  [types.ACTION_GET_OTP_INBOXES]: ({ commit }) =>
    portal
      .get('?method=messaging.getOtpInboxesForLoggedInProfile')
      .then(response => {
        commit(types.MUTATE_OTP_INBOXES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      }),
  [types.ADD_RECIPIENTS_TO_THREAD]: ({ commit, dispatch }, payload) =>
    portal
      .post('?method=messaging.addRecipients', {
        recipients: payload.recipients,
        threadId: payload.subscriptionId,
        commonInboxId: payload.commonInboxId || null,
        otpInboxId: payload.otpInboxId || null,
      })
      .then(response => {
        commit(types.MUTATE_SET_SUBSCRIPTIONS_ON_THREAD, {
          subscriptions: response.data.data,
          subscriptionId: payload.subscriptionId,
          relationships: payload.relationships,
          recipients: payload.recipients,
        });
        if (payload.recipients.length > 1) {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_ADDED_RECIPIENTS');
        } else {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_ADDED_RECIPIENT');
        }
        dispatch(types.SELECT_SUBSCRIPTION, {
          subscriptionId: state.shownSubscriptionId,
          subscriptionLeft: false,
          mailOwnerId: payload.commonInboxId,
          otpInboxId: payload.otpInboxId,
        });
        return response;
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_ADD_RECIPIENTS');
        return Promise.reject(error.response.data);
      }),
  [types.LOAD_COMMON_INBOXES]: ({ commit, rootState }, payload) => {
    portal
      .get('?method=messaging.getCommonInboxes', {
        params: {
          institutionProfileIds: payload.instProfileId,
        },
      })
      .then(response => {
        commit(types.MUTATE_SET_COMMON_INBOXES, response.data.data);
        commit(types.MUTATE_TOGGLE_COMMON_INBOX_FOLDERS, {
          notifications: rootState.notifications.notifications,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_COMMON_INBOXES');
      });
  },
  [types.LOAD_ALL_COMMON_INBOXES]: ({ commit, rootState }) => {
    portal
      .get('?method=messaging.getCommonInboxes', {
        params: {
          institutionProfileIds: rootState.globalShared.institutions.map(inst => inst.institutionProfileId),
        },
      })
      .then(response => {
        commit(types.MUTATE_SET_ALL_COMMON_INBOXES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_COMMON_INBOXES');
      });
  },
  [types.ACTIONS_ADJUST_SENSITIVITY]: ({ commit }, payload) => {
    let commonInboxId = null;
    let otpInboxId = null;
    if (
      state.chosenFolderAndMailOwner != null &&
      state.chosenFolderAndMailOwner.mailOwnerId &&
      state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX
    ) {
      commonInboxId = state.chosenFolderAndMailOwner.mailOwnerId;
    }
    if (
      state.chosenFolderAndMailOwner != null &&
      state.chosenFolderAndMailOwner.mailOwnerId &&
      state.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
    ) {
      otpInboxId = state.chosenFolderAndMailOwner.mailOwnerId;
    }
    return portal
      .post('?method=messaging.setSensitivityLevel', {
        threadId: payload.subscriptionId,
        sensitivityLevel: payload.sensitivityLevel,
        commonInboxId,
        otpInboxId,
      })
      .then(() => {
        commit(types.MUTATE_ADJUST_SENSITIVITY, payload);
        if (payload.showWToaster) {
          commit(types.MUTATE_SUCCESS_TEXT, 'SECURE_DOCUMENT_ADDED_WAIT');
        } else {
          if (payload.sensitivityLevel == sensitivityLevel.HIGH) {
            commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_MARK_AS_SENSITIVE');
          } else {
            commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MESSAGES_UNMARK_AS_SENSITIVE');
          }
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_SENSITIVITY_LEVEL');
      });
  },
  [types.ACTION_GET_BLOCKED_COMMUNICATION]: ({ commit }, payload) => {
    let profileIds = [];
    if (payload.profiles != null) {
      for (const profile of payload.profiles) {
        if (profile.role !== messageOwnerTypes.COMMON_INBOX && profile.portalRole !== portalRoles.OTP) {
          profileIds.push(profile.id);
        }
      }
      profileIds = [...new Set(profileIds)];
    }
    let otpInboxIds = [];
    if (payload.otpInboxIds) {
      otpInboxIds = [...new Set(payload.otpInboxIds)];
    }
    let groupSharings = [];
    if (payload.groupSharings) {
      groupSharings = payload.groupSharings;
    }
    return portal
      .post('?method=municipalConfiguration.getBlockedCommunicationInstitutionProfilesAndGroups', {
        creatorInstitutionProfileId: payload.creator,
        recipientInstitutionProfileIds: profileIds,
        groupSharings: groupSharings,
        otpInboxIds: otpInboxIds,
      })
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_BLOCKED_COMMUNICATION_PROFILES');
      });
  },
  [types.ATTACH_MESSAGES_INTO_DOCUMENT]: ({ commit }, payload) =>
    portal
      .post('?method=messaging.attachMessagesToSecureDocument', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_ADDED_TO_SECURE_DOCUMENT_TOASTR');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_ATTACH_MESSAGES_TO_DOCUMENT');
      }),
  [types.LOAD_SINGLE_THREAD]: ({ commit }, payload) => {
    const params = {
      threadIds: [payload.threadId],
      mailBoxOwnerType: payload.mailBoxOwnerType,
      mailBoxOwnerIds: payload.mailOwnerId ? [payload.mailOwnerId] : null,
      page: 0,
    };
    return portal
      .get('?method=messaging.getThreads', { params })
      .then(response => {
        if (response.data.data.threads.length > 0) {
          return commit(types.MUTATE_SINGLE_THREAD, {
            thread: response.data.data.threads[0],
          });
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      });
  },
  [types.LOAD_THREADS_IN_BUNDLE_BY_ID]: ({ commit, rootState }, payload) =>
    portal
      .get('?method=messaging.getThreadsInBundle', {
        params: payload,
      })
      .then(response => {
        const payload = response.data.data;
        payload.encryptionKey = rootState.globalShared.currentProfile.encryptionKey;
        commit(types.MUTATE_ADD_THREADS_IN_BUNDLE_BY_ID, payload);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MESSAGES_FOR_THREAD');
      }),
  [types.RESET_THREAD_BUNDLES]: ({ commit }) => {
    commit(types.MUTATE_RESET_THREAD_BUNDLES);
  },
  [types.DELETE_MESSAGE]: ({ commit }, { threadId, messageId }) =>
    portal
      .post('?method=messaging.deleteMessage', { threadId, messageId })
      .then(response => {
        const { deletedAt } = response.data.data;
        commit(types.MUTATE_DELETED_MESSAGE, { messageId, deletedAt });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_MESSAGE');
      }),
  [types.MESSAGES_LOAD_MESSAGE_REVISIONS_ADMIN]: ({ commit }, { instProfileId, start, end, startKey, limit }) => {
    const params = { instProfileId, start, end, startKey, limit };
    portal
      .post('?method=messagingAdmin.getMessageRevisions', params)
      .then(response => {
        const payload = response.data.data;
        commit(types.MUTATE_MESSAGE_REVISION_LIST_ADMIN, payload);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_MESSAGE_REVISIONS');
      });
  },
  [types.SEND_EVENT_REMINDER]: ({ commit }, { eventId, message }) =>
    portal
      .post('?method=messaging.sendEventReminder', { eventId, message })
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_SEND_REMINDER_SUCCESSFULLY');
      })
      .catch(error => {
        if (error.response.data.status.subCode === errorSubCodeTypes.communicationChannelBlocked) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_COMMUNICATION_CHANNELS_BLOCKED');
        }

        return Promise.reject(error.response.data);
      }),
};

function prepareShownSubscription(payloadData, arrayItem) {
  const result = Object.assign({}, payloadData);
  if (arrayItem != null && arrayItem.thread != null) {
    result.isForwarded = arrayItem.thread.isForwarded;
    result.requiresStepUp = arrayItem.thread.requiresStepUp;
    result.sensitivityLevel = arrayItem.thread.sensitivityLevel;
    result.sensitivityAcknowledged = arrayItem.sensitivityAcknowledged;
    result.leaveTime = arrayItem.leaveTime;
    result.muted = arrayItem.muted;
    result.subscriptionId = arrayItem.id;
  }
  return result;
}
function getCurrentFolderId(chosenFolderAndMailOwner) {
  return chosenFolderAndMailOwner?.folderId !== messageFiltersEnum.DEFAULT_FOLDER
    ? chosenFolderAndMailOwner.folderId
    : null;
}
function getFolderName(folderId) {
  let folder = null;
  const commonInbox = state.commonInboxes
    ? state.commonInboxes.find(inbox => inbox.id == state.chosenFolderAndMailOwner.mailOwnerId)
    : null;
  if (commonInbox) {
    folder = folderId ? commonInbox.folders.find(f => f.id == folderId) : commonInbox;
  } else {
    folder = state.threadsFolders ? state.threadsFolders.find(f => f.id == folderId) : null;
  }
  return folder ? folder.name : null;
}
function getActiveChildrenIds(rootState) {
  if (
    rootState.globalShared.currentProfile.role === portalRoles.GUARDIAN &&
    rootState.global.activeChildren.length !== rootState.global.children.length
  ) {
    return rootState.global.activeChildren;
  }
  return null;
}

function getMailboxOwnerParams(rootState, rootGetters, chosenFolderAndMailOwner) {
  let mailBoxOwnerIds;
  let mailBoxOwnerType;

  if (
    rootState.global.activeInstitutionProfiles.length > 0 &&
    !(rootState.global.activeInstitutionProfiles.length === rootState.globalShared.institutions.length)
  ) {
    mailBoxOwnerIds = rootState.global.activeInstitutionProfiles;
    mailBoxOwnerType = messageOwnerTypes.INSTITUTION_PROFILE;
  }

  if (rootState.globalShared.currentProfile.role === portalRoles.OTP) {
    mailBoxOwnerIds = rootGetters[types.MESSAGES_GET_OTP_INBOXES].map(item => item.id);
    mailBoxOwnerType = messageOwnerTypes.OTP_INBOX;
  } else if (chosenFolderAndMailOwner?.mailOwnerId) {
    mailBoxOwnerIds = [chosenFolderAndMailOwner.mailOwnerId];
    mailBoxOwnerType = messageOwnerTypes.COMMON_INBOX;
  }

  if (!mailBoxOwnerIds || (mailBoxOwnerIds && mailBoxOwnerIds.length === 0)) {
    mailBoxOwnerIds = mailBoxOwnerType = undefined;
  }

  return { mailBoxOwnerIds, mailBoxOwnerType };
}

function getSelectedMailBoxId(chosenFolderAndMailOwner, mailOwnerType = messageOwnerTypes.INSTITUTION_PROFILE) {
  if (chosenFolderAndMailOwner?.mailOwnerType === mailOwnerType) {
    return chosenFolderAndMailOwner.mailOwnerId;
  }
  return null;
}

function getSubscriptionsAndBundleThreads() {
  const threadIdsDictionary = {};
  const uniqueThreads = [];

  for (const bundle of state.threadBundles) {
    uniqueThreads.push(...bundle.threads);
  }
  for (const thread of state.subscriptions) {
    if (!threadIdsDictionary[thread.id]) {
      uniqueThreads.push(thread);
      threadIdsDictionary[thread.id] = true;
    }
  }
  return uniqueThreads;
}

function appendNewThreads(newThreads) {
  for (const subscription of newThreads) {
    state.subscriptions = state.subscriptions.filter(sub => sub.id != subscription.id);
    state.subscriptions.unshift(subscription);
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
};

function getDefaultState() {
  return {
    subscriptions: [],
    shownSubscriptionId: -1,
    shownSubscription: null,
    lastReadMessageId: null,
    inSearch: false,
    messages: {},
    moreSubscriptionsExist: false,
    currentSubscriptonPage: 0,
    messagesInSelectMode: false,
    draftThreads: {},
    draftMessages: {},
    currentDraftMessage: '',
    oldDraftMessage: null,
    oldDraftId: null,
    unreadThreads: 0,
    oldDraftThreadId: null,
    selectedDraft: {},
    threadsFolders: [],
    threadsFoldersForCommonInbox: [],
    messagesFolders: {},
    selectedThreadIds: [],
    selectedSubscriptionIds: [],
    selectedBundleIds: [],
    autoReply: null,
    showChooseSubscriptions: false,
    selectableMailBoxOwners: [],
    typeaheadSuggestions: [],
    commonInboxes: [],
    allCommonInboxes: [],
    singleMessage: {},
    preFilledNewEmailContact: null,
    moreMessagesExist: false,
    currentMessagePage: 0,
    chosenFolderAndMailOwner: {},
    latestMovedThreads: {
      currentFolder: '',
      threadIds: null,
      moveToFolderId: null,
    },
    isLoadingThreads: false,
    singleThread: null,
    messagesSearchQuery: '',
    selectedRecipients: [],
    otpInboxes: [],
    threadBundles: [],
    selectedBundleId: null,
    hasNewThreads: false,
    hasNewMessages: false,
    messageRevisionList: { messageRevisions: [], lastEvaluatedKey: null },
  };
}
