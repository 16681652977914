// Interface for all getters, mutations and actions

// Getters
export const IS_LOADING_THREADS = 'messages/IS_LOADING_THREADS';
export const MESSAGES_GET_OTP_INBOXES = 'messages/MESSAGES_GET_OTP_INBOXES';
export const MESSAGES_GET_LATEST_MOVED_THREADS = 'messages/MESSAGES_GET_LATEST_MOVED_THREADS';
export const MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER = 'messages/MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER';
export const MESSAGES_GET = 'messages/MESSAGES_GET';
export const MESSAGES_GET_SUBSCRIPTIONS = 'messages/MESSAGES_GET_SUBSCRIPTIONS';
export const MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS = 'messages/MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS';
export const MESSAGES_GET_SUBSCRIPTIONS_INDEX = 'messages/MESSAGES_GET_SUBSCRIPTIONS_INDEX';
export const MESSAGES_GET_IN_SEARCH = 'messages/MESSAGES_GET_IN_SEARCH';
export const MESSAGES_GET_ACTIVE_SEARCH_QUERY = 'messages/MESSAGES_GET_ACTIVE_SEARCH_QUERY';
export const MESSAGES_GET_SELECTED_SUBSCRIPTION = 'messages/MESSAGES_GET_SELECTED_SUBSCRIPTION';
export const MESSAGES_GET_SELECTED_RECIPIENTS = 'messages/MESSAGES_GET_SELECTED_RECIPIENTS';
export const MESSAGES_GET_UNREAD_THREADS = 'messages/MESSAGES_GET_UNREAD_THREADS';
export const MESSAGES_GET_SELECTED_DRAFT = 'messages/MESSAGES_GET_SELECTED_DRAFT';
export const MESSAGES_GET_FOLDERS = 'messages/MESSAGES_GET_FOLDERS';
export const MESSAGES_GET_CURRENT_FOLDER = 'messages/MESSAGES_GET_CURRENT_FOLDER';
export const MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX = 'messages/MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX';
export const MESSAGES_GET_CHOSEN_THREAD_IDS = 'messages/MESSAGES_GET_CHOSEN_THREAD_IDS';
export const MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS = 'messages/MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS';
export const MESSAGES_GET_CHOSEN_BUNDLE_IDS = 'messages/MESSAGES_GET_CHOSEN_BUNDLE_IDS';
export const MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW = 'messages/MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW';
export const MESSAGES_GET_CLEAR_CHECKBOXES = 'messages/MESSAGES_GET_CLEAR_CHECKBOXES';
export const MESSAGES_GET_AUTOREPLY = 'messages/MESSAGES_GET_AUTOREPLY';
export const MESSAGES_GET_DRAFT_THREADS = 'messages/MESSAGES_GET_DRAFT_THREADS';
export const MESSAGES_GET_DRAFT_MESSAGES = 'messages/MESSAGES_GET_DRAFT_MESSAGES';
export const MESSAGES_GET_SELECTED_SUBSCRIPTION_ID = 'messages/MESSAGES_GET_SELECTED_SUBSCRIPTION_ID';
export const MESSAGES_GET_LAST_READ = 'messages/MESSAGES_GET_LAST_READ';
export const MESSAGES_GET_MORE_SUBSCRIPTIONS_EXIST = 'messages/MESSAGES_GET_MORE_SUBSCRIPTIONS_EXIST';
export const MESSAGES_GET_MAIL_BOX_OWNERS = 'messages/MESSAGES_GET_MAIL_BOX_OWNERS';
export const GET_TYPEAHEAD_SUGGESTIONS = 'messages/GET_TYPEAHEAD_SUGGESTIONS';
export const MESSAGES_GET_ENCRYPTION_KEY = 'messages/GET_ENCRYPTION_KEY';
export const MESSAGES_GET_COMMON_INBOXES = 'messages/MESSAGES_GET_COMMON_INBOXES';
export const MESSAGES_GET_ALL_COMMON_INBOXES = 'messages/MESSAGES_GET_ALL_COMMON_INBOXES';
export const MESSAGES_GET_CURRENT_DRAFT_MESSAGE = 'messages/MESSAGES_GET_CURRENT_DRAFT_MESSAGE';
export const MESSAGES_GET_IN_SELECT_MODE = 'messages/MESSAGES_GET_IN_SELECT_MODE';
export const MESSAGES_GET_OLD_DRAFT_THREAD_ID = 'messages/MESSAGES_GET_OLD_DRAFT_THREAD_ID';
export const MESSAGES_GET_MORE_MESSAGES_EXITS = 'messages/MESSAGES_GET_MORE_MESSAGES_EXITS';
export const MESSAGES_GET_PREFILLED_NEW_EMAIL_CONTACT = 'messages/MESSAGES_GET_PREFILLED_NEW_EMAIL_CONTACT';
export const MESSAGES_GET_SINGLE_THREAD = 'messages/MESSAGES_GET_SINGLE_THREAD';
export const MESSAGES_GET_THREAD_BUNDLES = 'messages/MESSAGES_GET_THREAD_BUNDLES';
export const MESSAGES_SELECTED_BUNDLE_ID = 'messages/MESSAGES_SELECTED_BUNDLE_ID';
export const MESSAGES_HAS_NEW_THREADS = 'messages/MESSAGES_HAS_NEW_THREADS';
export const MESSAGES_HAS_NEW_MESSAGES = 'messages/MESSAGES_HAS_NEW_MESSAGES';
export const MESSAGES_GET_MESSAGE_REVISION_LIST_ADMIN = 'messages/MESSAGES_GET_MESSAGE_REVISION_LIST_ADMIN';

// Mutations
export const MUTATE_DRAFT_MESSAGES_FROM_LOCAL_STORAGE = 'messages/MUTATE_DRAFT_MESSAGES_FROM_LOCAL_STORAGE';
export const MUTATE_MESSAGES_RESET_STATE = 'messages/MUTATE_RESET_STATE';
export const MUTATE_IS_LOADING_THREADS = 'messages/MUTATE_IS_LOADING_THREADS';
export const MUTATE_OTP_INBOXES = 'messages/MUTATE_OTP_INBOXES';
export const MUTATE_CHOSEN_FILTER = 'messages/MUTATE_CHOSEN_FILTER';
export const MUTATE_SELECTED_RECIPIENTS = 'messages/MUTATE_SELECTED_RECIPIENTS';
export const MUTATE_LATEST_MOVED_THREADS = 'messages/MUTATE_LATEST_MOVED_THREADS';
export const MUTATE_HIDE_MOVED_THREADS = 'messages/MUTATE_HIDE_MOVED_THREADS';
export const MUTATE_CHOSEN_FOLDER_AND_MAIL_OWNER = 'messages/MUTATE_CHOSEN_FOLDER_AND_MAIL_OWNER';
export const MUTATE_INCREMENT_UNREAD_THREADS = 'messages/MUTATE_INCREMENT_UNREAD_THREADS';
export const MUTATE_DECREMENT_UNREAD_THREADS = 'messages/MUTATE_DECREMENT_UNREAD_THREADS';
export const MUTATE_SELECT_SUBSCRIPTION = 'messages/MUTATE_SELECT_SUBSCRIPTION';
export const MUTATE_SUBSCRIPTIONS_IN_SEARCH = 'messages/MUTATE_SUBSCRIPTIONS_IN_SEARCH';
export const MUTATE_SUBSCRIPTIONS_SEARCH_QUERY = 'messages/MUTATE_SUBSCRIPTIONS_SEARCH_QUERY';
export const MUTATE_RESET_SELECT_SUBSCRIPTION = 'messages/MUTATE_RESET_SELECT_SUBSCRIPTION';
export const MUTATE_RESET_SELECTED_DRAFT = 'messages/MUTATE_RESET_SELECTED_DRAFT';
export const MUTATE_SUBMIT_MESSAGE = 'messages/MUTATE_SUBMIT_MESSAGE';
export const MUTATE_THREAD_LATEST_MESSAGE = 'messages/MUTATE_THREAD_LATEST_MESSAGE';
export const MUTATE_INIT_SUBSCRIPTIONS = 'messages/MUTATE_INIT_SUBSCRIPTIONS';
export const MUTATE_INIT_SUBSCRIPTIONS_ADM = 'messages/MUTATE_INIT_SUBSCRIPTIONS_ADM';
export const MUTATE_APPEND_SUBSCRIPTIONS = 'messages/MUTATE_APPEND_SUBSCRIPTIONS';
export const MUTATE_ADD_SUBSCRIPTION = 'messages/MUTATE_ADD_SUBSCRIPTION';
export const MUTATE_INIT_THREADS = 'messages/MUTATE_INIT_THREADS';
export const MUTATE_RESET_SUBSCRIPTION_LIST = 'messages/MUTATE_RESET_SUBSCRIPTION_LIST';
export const MUTATE_INIT_THREADS_FOLDERS = 'messages/MUTATE_INIT_THREADS_FOLDERS';
export const MUTATE_COMMON_INBOX_FOLDERS = 'messages/MUTATE_COMMON_INBOX_FOLDERS';
export const MUTATE_DELETE_THREADS_FOLDER = 'messages/MUTATE_DELETE_THREADS_FOLDER';
export const MUTATE_CHANGE_FOLDER_NAME = 'messages/MUTATE_CHANGE_FOLDER_NAME';
export const MUTATE_INIT_MESSAGES = 'messages/MUTATE_INIT_MESSAGES';
export const MUTATE_CHOSEN_THREAD_IDS = 'messages/MUTATE_CHOSEN_THREAD_IDS';
export const MUTATE_CHOSEN_SUBSCRIPTION_IDS = 'messages/MUTATE_CHOSEN_SUBSCRIPTION_IDS';
export const MUTATE_CHOSEN_BUNDLE_IDS = 'messages/MUTATE_CHOSEN_BUNDLE_IDS';
export const MUTATE_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW = 'messages/MUTATE_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW';
export const MUTATE_MESSAGES_MUTE = 'messages/MUTATE_MESSAGES_MUTE';
export const MUTATE_INIT_AUTOREPLY = 'messages/MUTATE_INIT_AUTOREPLY';
export const MUTATE_CREATE_AUTOREPLY = 'messages/MUTATE_CREATE_AUTOREPLY';
export const MUTATE_DELETE_AUTOREPLY = 'messages/MUTATE_DELETE_AUTOREPLY;';
export const MUTATE_LEAVE_SUBSCRIPTION = 'messages/MUTATE_LEAVE_SUBSCRIPTION';
export const MUTATE_LEAVE_SUBSCRIPTIONS = 'messages/MUTATE_LEAVE_SUBSCRIPTIONS';
export const MUTATE_CLEAR_CHECKBOXES = 'messages/MESSAGES_MUTATE_CLEAR_CHECKBOXES';
export const MUTATE_SWITCH_MARK_SUBSCRIPTIONS = 'messages/MUTATE_SWITCH_MARK_SUBSCRIPTIONS';
export const MUTATE_SWITCH_SUBSCRIPTIONS_READ_STATUS = 'messages/MUTATE_SWITCH_SUBSCRIPTIONS_READ_STATUS';
export const MUTATE_BUNDLE_SUBSCRIPTIONS_CHECKED_STATUS_BY_BUNDLE_ID =
  'messages/MUTATE_BUNDLE_SUBSCRIPTIONS_CHECKED_STATUS_BY_BUNDLE_ID';
export const MUTATE_SUBSCRIPTIONS_CHECKED_STATUS = 'messages/MUTATE_SUBSCRIPTIONS_CHECKED_STATUS';
export const MUTATE_UNCHECK_ALL_SUBSCRIPTIONS = 'messages/MUTATE_UNCHECK_ALL_SUBSCRIPTIONS';
export const MUTATE_DELETE_SUBSCRIPTION_BY_ID = 'messages/MUTATE_DELETE_SUBSCRIPTION_BY_ID';
export const MUTATE_DELETE_SUBSCRIPTIONS = 'messages/MUTATE_DELETE_SUBSCRIPTIONS';
export const MUTATE_DELETE_DRAFT_THREAD = 'messages/MUTATE_DELETE_DRAFT_THREAD';
export const MUTATE_SELECT_DRAFT_THREAD = 'messages/MUTATE_SELECT_DRAFT_THREAD';
export const MUTATE_SEARCH_SUBSCRIPTIONS = 'messages/MUTATE_SEARCH_SUBSCRIPTIONS';
export const MUTATE_LOAD_TYPEAHEAD_SUGGESTIONS = 'messages/MUTATE_LOAD_TYPEAHEAD_SUGGESTIONS';
export const MUTATE_SELECTABLE_MAIL_BOX_OWNERS = 'messages/MUTATE_SELECTABLE_MAIL_BOX_OWNERS';
export const MUTATE_UPDATE_CURRENT_DRAFT_MESSAGE = 'messages/MUTATE_UPDATE_CURRENT_DRAFT_MESSAGE';
export const MUTATE_SAVE_DRAFT_MESSAGE = 'messages/MUTATE_SAVE_DRAFT_MESSAGE';
export const MUTATE_SET_SUBSCRIPTIONS_ON_THREAD = 'messages/MUTATE_SET_SUBSCRIPTIONS_ON_THREAD';
export const MUTATE_TOGGLE_COMMON_INBOX_FOLDERS = 'messages/MUTATE_TOGGLE_COMMON_INBOX_FOLDERS';
export const MUTATE_SET_COMMON_INBOXES = 'messages/MUTATE_SET_COMMON_INBOXES';
export const MUTATE_SET_ALL_COMMON_INBOXES = 'messages/MUTATE_SET_ALL_COMMON_INBOXES';
export const MUTATE_ADD_TO_DRAFT_THREAD = 'messages/MUTATE_ADD_TO_DRAFT_THREAD';
export const MUTATE_ADJUST_SENSITIVITY = 'messages/MUTATE_ADJUST_SENSITIVITY';
export const MUTATE_ADJUST_ACKNOWLEDGED_SENSITIVITY = 'messages/MUTATE_ADJUST_ACKNOWLEDGED_SENSITIVITY';
export const MUTATE_RESET_SHOWN_SUBSCRIPTION = 'messages/MUTATE_RESET_SHOWN_SUBSCRIPTION';
export const MUTATE_SELECTALL_MESSAGES = 'messages/MUTATE_SELECTALL_MESSAGES';
export const MUTATE_MESSAGES_IN_SELECT_MODE = 'messages/MUTATE_MESSAGES_IN_SELECT_MODE';
export const MUTATE_SELECT_MESSAGE = 'messages/MUTATE_SELECT_MESSAGE';
export const MUTATE_DELETED_MESSAGE = 'messages/MUTATE_DELETED_MESSAGE';
export const MUTATE_EDITED_MESSAGE = 'messages/MUTATE_EDITED_MESSAGE';
export const MUTATE_APPEND_MESSAGES = 'messages/MUTATE_APPEND_MESSAGES';
export const MUTATE_PREFILLED_NEW_EMAIL_CONTACT = 'messages/MUTATE_PREFILLED_NEW_EMAIL_CONTACT';
export const MUTATE_RESET_PREFILLED_NEW_EMAIL_CONTACT = 'messages/MUTATE_RESET_PREFILLED_NEW_EMAIL_CONTACT';
export const MUTATE_SINGLE_THREAD = 'messages/MUTATE_SINGLE_THREAD';
export const MUTATE_ADD_THREADS_IN_BUNDLE_BY_ID = 'messages/MUTATE_ADD_THREADS_IN_BUNDLE_BY_ID';
export const MUTATE_RESET_THREAD_BUNDLES = 'messages/MUTATE_RESET_THREAD_BUNDLES';
export const MUTATE_SELECTED_BUNDLE_ID = 'messages/MUTATE_SELECTED_BUNDLE_ID';
export const MUTATE_HAS_NEW_THREADS = 'messages/MUTATE_HAS_NEW_THREADS';
export const MUTATE_HAS_NEW_MESSAGES = 'messages/MUTATE_HAS_NEW_MESSAGES';
export const MUTATE_MESSAGE_REVISION_LIST_ADMIN = 'mesages/MUTATE_MESSAGE_REVISION_LIST_ADMIN';
export const MUTATE_RESET_MESSAGE_REVISION_LIST_ADMIN = 'mesages/MUTATE_RESET_MESSAGE_REVISION_LIST_ADMIN';

// Actions
export const INCREMENT_UNREAD_MESSAGES = 'messages/INCREMENT_UNREAD_THREADS';
export const DECREMENT_UNREAD_MESSAGES = 'messages/DECREMENT_UNREAD_THREADS';
export const SET_LAST_READ_MESSAGE = 'messages/SET_LAST_READ_MESSAGE';
export const SELECT_SUBSCRIPTION = 'messages/SELECT_SUBSCRIPTION';
export const REPLY_MESSAGE = 'messages/REPLY_MESSAGE';
export const EDIT_MESSAGE = 'messages/EDIT_MESSAGE';
export const RESET_SELECT_SUBSCRIPTION = 'messages/RESET_SELECT_SUBSCRIPTION';
export const START_NEW_THREAD = 'messages/START_NEW_THREAD';
export const INIT_THREADS_FOLDERS = 'messages/INIT_THREADS_FOLDERS';
export const DELETE_SUBSCRIPTIONS = 'messages/DELETE_SUBSCRIPTIONS';
export const DELETE_THREADS_FOLDER = 'messages/DELETE_THREADS_FOLDER';
export const CHANGE_FOLDER_NAME = 'messages/CHANGE_FOLDER_NAME';
export const ADD_THREADS_FOLDER = 'messages/ADD_THREADS_FOLDER';
export const ADD_THREADS_FOLDER_FOR_COMMON_INBOX = 'messages/ADD_THREADS_FOLDER_FOR_COMMON_INBOX';
export const LOAD_COMMON_INBOX_FOLDERS = 'messages/LOAD_COMMON_INBOX_FOLDERS';
export const UPDATE_THREADS_VIEW = 'messages/UPDATE_THREADS_VIEW';
export const UPDATE_THREADS_VIEW_BY_SUBSCRIPTION = 'messages/UPDATE_THREADS_VIEW_BY_SUBSCRIPTION';
export const INIT_SUBSCRIPTIONS = 'messages/INIT_SUBSCRIPTIONS';
export const INIT_SUBSCRIPTIONS_ADM = 'messages/INIT_SUBSCRIPTIONS_ADM';
export const LOAD_MORE_SUBSCRIPTIONS = 'messages/LOAD_MORE_SUBSCRIPTIONS';
export const ADD_SUBSCRIPTION = 'messages/ADD_SUBSCRIPTION';
export const ACTION_UPDATE_SELECTED_THREAD_IDS = 'messages/ACTION_UPDATE_SELECTED_THREAD_IDS';
export const ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS = 'messages/ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS';
export const ACTION_UPDATE_SELECTED_BUNDLE_IDS = 'messages/ACTION_UPDATE_SELECTED_BUNDLE_IDS';
export const SUBSCRIPTIONS_ONPRESS_MOBILEVIEW = 'messages/SUBSCRIPTIONS_ONPRESS_MOBILEVIEW';
export const MESSAGES_LEAVE = 'messages/MESSAGES_LEAVE';
export const MESSAGES_LEAVE_THREADS = 'messages/MESSAGES_LEAVE_THREADS';
export const MESSAGES_SAVE_DRAFT_MESSAGE = 'messages/MESSAGES_SAVE_DRAFT_MESSAGE';
export const MESSAGES_EMPTY_CURRENT_DRAFT_MESSAGE = 'messages/MESSAGES_EMPTY_CURRENT_DRAFT_MESSAGE';
export const MESSAGES_CLEAR_CHECKBOXES = 'messages/MESSAGES_CLEAR_CHECKBOXES';
export const CREATE_AUTOREPLY = 'messages/CREATE_AUTOREPLY';
export const DELETE_AUTOREPLY = 'messages/DELETE_AUTOREPLY';
export const INIT_AUTOREPLY = 'messages/INIT_AUTOREPLY';
export const SWITCH_MARK_SUBSCRIPTIONS = 'messages/SWITCH_MARK_SUBSCRIPTIONS';
export const MESSAGES_UPDATE_SUBSCRIPTION_STATUS = 'messages/MESSAGES_UPDATE_SUBSCRIPTION_STATUS';
export const MESSAGES_SAVE_DRAFT_THREAD = 'messages/MESSAGES_SAVE_DRAFT_THREAD';
export const MOVE_THREADS_TO_FOLDER = 'messages/MOVE_THREADS_TO_FOLDER';
export const SEARCH_SUBSCRIPTIONS = 'messages/SEARCH_SUBSCRIPTIONS';
export const LOAD_TYPEAHEAD_SUGGESTIONS = 'messages/LOAD_TYPEAHEAD_SUGGESTIONS';
export const SET_SELECTABLE_MAIL_BOX_OWNERS = 'messages/SET_SELECTABLE_MAIL_BOX_OWNERS';
export const ACTION_GET_OTP_INBOXES = 'messages/ACTION_GET_OTP_INBOXES';
export const ADD_RECIPIENTS_TO_THREAD = 'messages/ADD_RECIPIENTS_TO_THREAD';
export const LOAD_COMMON_INBOXES = 'messages/LOAD_COMMON_INBOXES';
export const LOAD_ALL_COMMON_INBOXES = 'messages/LOAD_ALL_COMMON_INBOXES';
export const SELECT_MAIL_OWNER = 'messages/SELECT_MAIL_OWNER';
export const ACTIONS_ADJUST_SENSITIVITY = 'messages/ACTIONS_ADJUST_SENSITIVITY';
export const ACTION_GET_BLOCKED_COMMUNICATION = 'messages/ACTION_GET_BLOCKED_COMMUNICATION';
export const ACTION_GET_NEW_THREADS = 'messages/ACTION_GET_NEW_THREADS';
export const ACTION_REFRESH_BUNDLES = 'messages/ACTION_REFRESH_BUNDLES';
export const LOAD_MORE_MESSAGES = 'messages/LOAD_MORE_MESSAGES';
export const ATTACH_MESSAGES_INTO_DOCUMENT = 'messages/ATTACH_MESSAGES_INTO_DOCUMENT';
export const LOAD_SINGLE_THREAD = 'messages/LOAD_SINGLE_THREAD';
export const LOAD_THREADS_IN_BUNDLE_BY_ID = 'messages/LOAD_THREADS_IN_BUNDLE_BY_ID';
export const RESET_THREAD_BUNDLES = 'messages/RESET_THREAD_BUNDLES';
export const DELETE_MESSAGE = 'messages/DELETE_MESSAGE';
export const SEND_EVENT_REMINDER = 'messages/SEND_EVENT_REMINDER';
export const MESSAGES_LOAD_MESSAGE_REVISIONS_ADMIN = 'messages/MESSAGES_LOAD_MESSAGE_REVISIONS_ADMIN';
